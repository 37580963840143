import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { processFieldsForB4 } from 'utils/crud.js';
import { getHourIntervalsForMetabase, getWeekdays } from 'utils/dates';
import { isEmptyObject } from 'utils/objects';
import { ANALYTICS } from 'constants/Entities';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import { getVisibleInOptions } from 'models/AnalyticsReportModel';
import SendToField from 'containers/Metrics/lists/SharedList/DashboardForm/SendToField';
import { LookerService } from 'services';

function getIntervalTypes() {
    return [
        {
            label: getLiteral('label_analytics_pro_scheduling_interval_type_hour'),
            value: 'hourly',
            iconType: 'event',
        },
        {
            label: getLiteral('label_analytics_pro_scheduling_interval_type_daily'),
            value: 'daily',
            iconType: 'event',
        },
        {
            label: getLiteral('label_analytics_pro_scheduling_interval_type_weekly'),
            value: 'weekly',
            iconType: 'weekly',
        },
    ];
}
export default class AnalyticsReportManager {
    processForServer = processFieldsForB4;

    getSchema = (defaultInputSchema) => {
        return [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'title',
                        type: 'text',
                        mandatory: true,
                        label: getLiteral('label_analytics_pro_report_title'),
                        inputAttrs: {
                            forceFocus: true,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'description',
                        type: 'textarea',
                        label: getLiteral('label_analytics_pro_report_description'),
                        inputAttrs: {
                            multiLine: true,
                            rows: 5,
                            rowsMax: 15,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'displayOnWidget',
                        fieldConfiguration: 'displayOnWidget',
                        type: 'bool',
                        label: getLiteral('label_analytics_pro_report_display_widget_reports'),
                        description: getLiteral(
                            'label_analytics_pro_report_display_widget_reports_description',
                        ),
                    },
                    /*                     {
                        ...defaultInputSchema,
                        id: 'environment',
                        type: 'singleValueList',
                        label: getLiteral('label_analytics_pro_environment_visiblity'),
                        description: getLiteral(
                            'label_analytics_pro_environment_visiblity_description',
                        ),
                        inputAttrs: {
                            list: 'tblSucursales',
                        },
                    }, */
                    {
                        ...defaultInputSchema,
                        id: 'visibleIn',
                        fieldConfiguration: 'visibleIn',
                        type: 'multipleValueList',
                        label: getLiteral('label_analytics_pro_report_visible_entities'),
                        labelMode: 'vertical',
                        hint: getLiteral('label_selectone'),
                        inputAttrs: {
                            options: getVisibleInOptions(),
                        },
                    },
                ],
            },
            {
                title: getLiteral('label_analytics_pro_scheduling_title_section'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'name',
                        type: 'text',
                        label: getLiteral('label_analytics_pro_scheduling_name'),
                        mandatory: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'schedule_type',
                        fieldConfiguration: 'schedule_type',
                        type: 'singleValueList',
                        label: getLiteral('label_analytics_pro_scheduling_interval_type'),
                        labelMode: 'vertical',
                        mandatory: true,
                        hint: getLiteral('label_selectone'),
                        inputAttrs: {
                            options: getIntervalTypes(),
                        },
                    },
                    {
                        id: 'intervalDay',
                        type: 'multipleField',
                        labelMode: 'vertical',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                id: 'schedule_day',
                                fieldConfiguration: 'schedule_day',
                                type: 'singleValueList',
                                label: getLiteral(
                                    'label_analytics_pro_scheduling_interval_type_day',
                                ),
                                labelMode: 'vertical',
                                mandatory: true,
                                hint: getLiteral('label_selectone'),
                                inputAttrs: {
                                    options: getWeekdays(true),
                                },
                            },
                            {
                                ...defaultInputSchema,
                                id: 'schedule_hour',
                                fieldConfiguration: 'schedule_hour',
                                type: 'singleValueList',
                                label: getLiteral(
                                    'label_analytics_pro_scheduling_interval_type_hour',
                                ),
                                labelMode: 'vertical',
                                mandatory: true,
                                hint: getLiteral('label_selectone'),
                                inputAttrs: {
                                    options: getHourIntervalsForMetabase(),
                                },
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        id: 'groups',
                        type: 'multipleValueList',
                        component: SendToField,
                        label: getLiteral('label_analytics_pro_scheduling_send_to'),
                        labelMode: 'vertical',
                        mandatory: true,
                        hint: getLiteral('label_selectone'),
                        inputAttrs: {},
                    },
                ],
            },
        ];
    };

    afterGetSchema({ schema, dependencyMap, dynamicMap }) {
        dynamicMap.displayOnWidget = { visibleIn: [true] };
        dynamicMap.schedule_type = {
            schedule_day: ['weekly'],
            schedule_hour: ['daily', 'weekly'],
        };
        return Promise.resolve({
            schema,
            dependencyMap,
            dynamicMap,
        });
    }

    beforeSave = (_, data) => {
        if (!data.displayOnWidget) data.visibleIn = [];
        return data;
    };

    beforeCrud = (id) =>
        Promise.all([
            id ? LookerService.getScheduling(id) : Promise.resolve(),
            Context.serverListManager.getList('tblTimezones'),
        ]).then(([scheduling]) => {
            let newScheduling = scheduling?.[0];
            if (!newScheduling) return {};

            const type = getIntervalTypes().filter(
                (type) => newScheduling.schedule_type === type.value,
            );

            newScheduling = {
                ...newScheduling,
                schedule_type: newScheduling.schedule_type
                    ? { label: type[0].label, value: type[0].value }
                    : null,
            };

            if (type?.[0]?.value === 'daily' || type?.[0]?.value === 'weekly') {
                const time = getHourIntervalsForMetabase().filter(
                    (hour) => newScheduling.schedule_hour === hour.value,
                );
                newScheduling = {
                    ...newScheduling,
                    schedule_hour: newScheduling.schedule_hour
                        ? { label: time[0].label, value: time[0].value }
                        : null,
                };
            }

            if (type?.[0]?.value === 'weekly') {
                const day = getWeekdays(true).filter(
                    (day) => newScheduling.schedule_day === day.value,
                );
                newScheduling = {
                    ...newScheduling,
                    schedule_day: newScheduling.schedule_day
                        ? { label: day[0].label, value: day[0].value }
                        : null,
                };
            }

            return {
                // Just one right now
                ...newScheduling,
                id,
                scheduling: newScheduling,
            };
        });

    afterSave = ({ data, originalData, result }) =>
        new Promise((resolve) => {
            if (data.scheduling?.name) {
                let scheduling = {
                    ...data.scheduling,
                    groups: data.scheduling?.groups?.map((group) => parseInt(group.value, 10)),
                    schedule_type: data.scheduling?.schedule_type?.value,
                    schedule_day: data.scheduling?.schedule_day?.value || '',
                    schedule_hour: data.scheduling?.schedule_hour?.value || '',
                };

                delete scheduling?.pdfExportSettings;
                // return;
                if (originalData.scheduling?.id) {
                    LookerService.updateScheduling(
                        data.id || originalData.scheduling?.id,
                        scheduling,
                    ).catch(() => {
                        errorToast({
                            text: getLiteral(
                                'label_analytics_pro_notification_scheduling_body_ko_update',
                            ),
                            title: getLiteral(
                                'label_analytics_pro_notification_scheduling_title_alert',
                            ),
                        });
                    });
                } else if (!data.scheduling?.id) {
                    LookerService.createScheduling(data.id || result.code, scheduling).catch(() => {
                        errorToast({
                            text: getLiteral(
                                'label_analytics_pro_notification_scheduling_body_ko_create',
                            ),
                            title: getLiteral(
                                'label_analytics_pro_notification_scheduling_title_alert',
                            ),
                        });
                    });
                }
            } else if (!data.scheduling && originalData.scheduling?.id && data.id) {
                LookerService.deleteScheduling(data.id, originalData.scheduling?.id).catch(() => {
                    errorToast({
                        text: getLiteral(
                            'label_analytics_pro_notification_scheduling_body_ko_delete',
                        ),
                        title: getLiteral(
                            'label_analytics_pro_notification_scheduling_title_alert',
                        ),
                    });
                });
            }

            resolve();
        });

    checkCustomErrors = (errors, _, data) => {
        // Avoid scheduling validation if removed
        if (!data.scheduling || (!data.scheduling?.id && !data.schedulingOpen)) {
            delete errors.name;
            delete errors.intervalType;
            delete errors.timezone;
            delete errors.groups;
            delete errors.schedule_type;

            if (isEmptyObject(errors)) errors.firstErrorField = null;
        }
        return errors;
    };

    shouldOpenDetail = () => {
        return false;
    };

    customInitList = () => {
        publish(`${REFRESH_TABLE}_${ANALYTICS.entity}`);
    };
}
