import React, { memo } from 'react';
import moment from 'moment';

import { isToday, isYesterday } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';

const WidgetActivitiesListImagesByDay = memo(({ date, images, handleImageClick }) => {
    // date must be a moment date
    if (!moment.isMoment(date)) return null;

    const dateString = date.format('dddd L');
    let dateToPrint = dateString;
    if (isToday(date)) dateToPrint = `${getLiteral('label_today')} - ${dateString}`;
    if (isYesterday(date)) dateToPrint = `${getLiteral('common_yesterday')} - ${dateString}`;

    const renderBlocks =
        images &&
        images.map((image, index) => {
            console.log(image);
            return (
                <div
                    key={index}
                    className="fm-widget-activities-list-image"
                    onClick={handleImageClick(image)}
                >
                    <img src={image.Url} />
                </div>
            );
        });

    return (
        <div className="fm-widget-activities-list-images__container">
            <div className="fm-widget-activities-list-images__day">{dateToPrint}</div>
            <div className="fm-widget-activities-list-images__block">{renderBlocks}</div>
        </div>
    );
});

export default WidgetActivitiesListImagesByDay;
