import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';

import Context from 'managers/Context';
import { ValueListActions } from 'actions';
import { PATH_IMAGE } from 'constants/Environment';

import PictureUpload from 'containers/components/crud/PictureUpload';
import { TextButtonBlueSky } from 'components/buttons';
import { ButtonPrimary } from 'components/buttons';
import Loading from 'components/Loading';

import PasswordDialog from './components/PasswordDialog';

import TextField from 'components/Fields/Text';
import SelectField from 'components/Fields/ServerList';
import { USERS } from 'constants/Entities';

import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

const mapStateToProps = (state) => {
    const strCellPhoneSchema =
        state.config?.standardFieldsSchemaMap?.[USERS.extraFieldName]?.strCellPhone;
    const mobileReadOnly = strCellPhoneSchema?.readOnly;
    const mobileMandatory = strCellPhoneSchema?.mandatory;

    return {
        mobileReadOnly,
        mobileMandatory,
        canUploadPhoto: state.config?.permission.user_Upload_Picture,
    };
};

function mapDispatchToProps(dispatch) {
    const { getValueList } = bindActionCreators(ValueListActions, dispatch);
    return {
        getValueList,
    };
}

const ProfileSettingsContent = memo(({ canUploadPhoto, mobileReadOnly, mobileMandatory }) => {
    const schema = Context.profileSettingsManager.schema;
    const [model, setModel] = useState({
        ...Context.profileSettingsManager.get(),
    });
    const [errors, setErrors] = useState({});
    const [error, setError] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [updatable, setUpdatable] = useState(false);
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);

    useEffect(() => {
        loadCountryCodes();
    }, [loadCountryCodes]);

    useEffect(() => {
        let areaCode = null;
        if (model.idPrefix > 0) {
            let countryCodeFounded = countryCodes?.filter((item) => item['-id'] === model.idPrefix);
            areaCode = countryCodeFounded?.length > 0 ? countryCodeFounded[0].phoneprefix : null;
        }
        setModel((prevModel) => {
            return { ...prevModel, areaCode: areaCode };
        });
    }, [model.idPrefix, countryCodes]);

    const loadCountryCodes = useCallback(() => {
        Context.profileSettingsManager.getCountryCodes((data) => {
            setCountryCodes(data);
        });
    }, []);

    const onChange = useCallback(
        (field) => {
            return (value) => {
                let newModel = {
                    ...model,
                    [field.id]: value,
                };

                // Check errors
                Context.profileSettingsManager
                    .getErrors(newModel)
                    .then(({ errors, firstErrorField }) => {
                        // Update field data
                        if (firstErrorField) {
                            setModel(newModel);
                            setUpdatable(false);
                            setErrors(errors);
                            setError(true);
                        } else {
                            // Update field data
                            setModel(newModel);
                            setUpdatable(true);
                            setErrors({});
                            setError(false);
                        }
                    });
            };
        },
        [model],
    );

    const onChangeImage = useCallback(
        (file) => {
            setModel({ ...model, photo: file });
            setUpdatable(true);
        },
        [model],
    );

    const onUpdate = useCallback(() => {
        if (!updatable || error) return;
        setUpdatable(false);
        setLoadingSave(true);

        Context.profileSettingsManager.save(
            model,
            () => {
                successToast({
                    title: getLiteral('succes_data_saved'),
                    text: getLiteral('succes_entityupdatedsuccessfully'),
                });
                setLoadingSave(false);
            },
            (error) => {
                let errorMsg;
                let errors = {};
                switch (JSON.parse(error).message) {
                    case 'phone_duplicated':
                        errors = { ...errors, movil: getLiteral('label_duplicated_phone') };
                        errorMsg = getLiteral('label_duplicated_phone');
                        break;
                    case 'email_duplicated':
                        errors = { ...errors, email: getLiteral('label_duplicated_email') };
                        errorMsg = getLiteral('label_duplicated_email');
                        break;
                    default:
                        errorMsg = getLiteral('error_error');
                        break;
                }
                setError(true);
                setErrors(errors);
                errorToast({ text: getLiteral('error_an_error_occurred') });
                setUpdatable(true);
                setLoadingSave(false);
            },
        );
    }, [error, model, updatable]);

    const getListMoreOptions = useCallback((options, list) => {
        if (!options) return options;
        return options.reduce((obj, option) => {
            obj.push({
                value: option.striso639_1 && option.striso639_1.toLowerCase(),
                label: option.label,
            });
            return obj;
        }, []);
    }, []);

    const getListMoreCountryCodes = useCallback((options, list) => {
        if (!options) return options;
        return options.reduce((obj, option) => {
            obj.push({
                value: option['-id'],
                label: option.strname,
            });
            return obj;
        }, []);
    }, []);

    const saveButton = useMemo(() => {
        return (
            <ButtonPrimary disabled={!updatable} onClick={onUpdate}>
                {loadingSave && <Loading size="xsmall" color="white" />}
                {!loadingSave && <Text color="neutralBase">{getLiteral('action_save')}</Text>}
            </ButtonPrimary>
        );
    }, [loadingSave, onUpdate, updatable]);

    const renderPasswordLine = useCallback(
        (description, index) => {
            return (
                <div key={index} className="profile-settings-field-container">
                    <div className="profile-settings-label">
                        <Text>{getLiteral(description)}</Text>
                    </div>
                    <div className="profile-settings-text-field profile-settings-hardwritten-password">
                        <span className="hidden-password">
                            <Text>{getLiteral('label_password_hidden_settings')}</Text>
                        </span>
                        <div className="profile-settings-change-password-btn">
                            <TextButtonBlueSky onClick={() => setShowPasswordDialog(true)}>
                                <Text>{getLiteral('label_change_password')}</Text>
                            </TextButtonBlueSky>
                        </div>
                    </div>
                    <PasswordDialog
                        show={showPasswordDialog}
                        onSave={() => setShowPasswordDialog(false)}
                        onClose={() => setShowPasswordDialog(false)}
                    />
                </div>
            );
        },
        [showPasswordDialog],
    );

    const renderForm = useCallback(() => {
        const render = schema.map((current) => {
            return current.tabFields.map((field, index) => {
                let label = getLiteral(field.description);
                let required = field.mandatory || false;
                let disabled = field.disabled || false;
                let hint = field.hint;
                let value = model[field.id];
                let error = errors[field.id];
                if (!label) return null;
                switch (field.id) {
                    case 'password':
                        return renderPasswordLine(label, index);
                    case 'langISOInterface':
                        return (
                            <SelectField
                                key={index}
                                error={error}
                                label={label}
                                mandatory={required}
                                readOnly={disabled}
                                list={field.valueListName}
                                value={value ? value.toLowerCase() : null}
                                getListMoreOptions={getListMoreOptions}
                                onChange={onChange(field)}
                            />
                        );
                    case 'idPrefix':
                        return (
                            <SelectField
                                key={index}
                                error={error}
                                label={label}
                                mandatory={required}
                                readOnly={disabled}
                                options={countryCodes}
                                value={value ? value.toLowerCase() : null}
                                getListMoreOptions={getListMoreCountryCodes}
                                onChange={onChange(field)}
                            />
                        );
                    case 'movil':
                        return (
                            <TextField
                                key={index}
                                error={error}
                                label={label}
                                hint={hint}
                                mandatory={mobileMandatory}
                                readOnly={mobileReadOnly}
                                value={value}
                                onInput={onChange(field)}
                                onChange={onChange(field)}
                            />
                        );
                    default:
                        return (
                            <TextField
                                key={index}
                                error={error}
                                label={label}
                                mandatory={required}
                                hint={hint}
                                readOnly={disabled}
                                value={value}
                                onInput={onChange(field)}
                                onChange={onChange(field)}
                            />
                        );
                }
            });
        });
        return render;
    }, [
        countryCodes,
        errors,
        getListMoreCountryCodes,
        getListMoreOptions,
        model,
        onChange,
        renderPasswordLine,
        schema,
        mobileMandatory,
        mobileReadOnly,
    ]);

    const getImg = useMemo(() => {
        let img = `${PATH_IMAGE}profile.png`;
        if (model.photo) {
            img = `data:image/png;base64,${model.photo}`;
        } else {
            img = getSrcUserCircleAvatar(model.idUsuario)?.src;
        }
        return img;
    }, [model.idUsuario, model.photo]);

    return (
        <div>
            <div className="profile-settings-picture">
                <PictureUpload src={getImg} onChange={onChangeImage} canChange={canUploadPhoto} />
            </div>
            <form className="profile-settings-form" autoComplete="off">
                {renderForm()}
                <div className="profile-settings-save-button">{saveButton}</div>
            </form>
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettingsContent);
