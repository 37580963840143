import { Map } from 'immutable';
import { getLiteral } from 'utils/getLiteral';
import Context from 'managers/Context';
import { CAMPAIGNS, COMPANIES, DOCUMENTS } from 'constants/Entities';
import { ensureRoute, backRoute } from 'utils/routes';
import { CampaignsService } from 'services';
import moment from 'moment';
const FilterSchema = [];

export default class CampaignsManager {
    initAccountSelection = (id) =>
        Context.domainManager.sendEntityListSelection({
            selectionForId: id,
            selectionFor: 'campaigns',
            entity: 'accounts',
        });

    getSchema = () => [
        {
            // title: null,
            // show: false,
            fields: [
                {
                    id: 'title',
                    out: true,
                    type: 'text',
                    mandatory: true,
                    inputAttrs: {
                        maxLength: 100,
                    },
                },
                {
                    id: 'questions',
                    out: true,
                    type: 'arrayObjects',
                },
                {
                    id: 'descriptionRich', // different from serverId's
                    type: 'richtext',
                    serverId: {
                        html: 'description',
                        text: 'descriptionText',
                    },
                    mandatory: true,
                    label: getLiteral('label_campaigns_crud_details_description'),
                    hint: getLiteral('label_campaigns_edit_define_description_example'), // Placeholder
                    description: getLiteral('label_campaigns_crud_details_description_info'), // Tooltip
                    inputAttrs: {
                        maxLength: 500,
                        labelMode: 'vertical',
                    },
                },
                {
                    id: 'campaignDate',
                    serverId: {
                        from: 'campaignStartDate',
                        to: 'campaignEndDate',
                    },
                    type: 'dateRange',
                    mandatory: true,
                    label: getLiteral('label_campaigns_crud_details_period'),
                    description: getLiteral('label_campaigns_crud_details_period_info'), // Tooltip
                    inputAttrs: {
                        // Directly to input
                        from: 'campaignStartDate',
                        to: 'campaignEndDate',
                        syncFromTo: true,
                    },
                },
                {
                    id: 'typeId',
                    serverType: Number,
                    type: 'select',
                    mandatory: true,
                    label: getLiteral('label_campaigns_crud_details_type'),
                    description: getLiteral('label_campaigns_crud_details_type_info'), // Tooltip
                    inputAttrs: {
                        list: 'tblCampaignsTypes',
                    },
                },
                {
                    id: 'videoUrl',
                    type: 'url',
                    mandatory: false,
                    label: getLiteral('label_video_campaign'),
                    hint: getLiteral('placeholder_add_video_url'), // Placeholder
                    description: getLiteral('label_add_video_to_give_more_info'), // Tooltip
                    inputAttrs: {
                        maxLength: 2048,
                    },
                },
                {
                    id: 'statusId',
                    serverType: Number,
                    type: 'select',
                    out: true,
                },
                {
                    id: 'files',
                    type: 'files',
                    out: true,
                },
            ],
        },
    ];

    checkCustomErrors = (errors) => {
        const entityListSelect = Context.store.getState().entityListSelect;
        const companiesSelect = entityListSelect[COMPANIES.entity] || entityListSelect;

        if (!companiesSelect || !companiesSelect.total || companiesSelect.total === 0) {
            errors = {
                ...errors,
                companiesSelect: 'mandatory',
            };
        } else {
            delete errors.companiesSelect;
        }
        return errors;
    };

    getDefaults = () =>
        new Promise((resolve) => {
            resolve({
                ownerId: Number(Context?.config?.userData?.idUsuario),
                statusId: 1,
                accounts: [],
                campaignQuestions: [],
            });
        });

    resetRelatedCompanies = () => {
        const resetListSelect = this.context.actions.EntityListSelectActions.resetListSelect(
            COMPANIES,
            false,
        );

        const hideFilters = this.context.actions.EntityFiltersActions.hideFilters(COMPANIES);

        this.context.store.dispatch(resetListSelect);
        this.context.store.dispatch(hideFilters);
    };

    beforeDetail = (id) =>
        new Promise((resolve, reject) => {
            // Getting extra data
            if (id) {
                Promise.all([this.getDocuments(id)])
                    .then(([files]) => {
                        resolve({
                            files: files || [],
                        });
                    })
                    .catch(reject);
            } else {
                resolve({});
            }
        });

    beforeCrud = (id) =>
        new Promise((resolve, reject) => {
            // Resetting related state
            this.resetRelatedCompanies();

            // Getting extra data
            if (id) {
                Promise.all([
                    this.initAccountSelection(id),
                    CampaignsService.getQuestions(id),
                    this.getDocuments(id),
                ])
                    .then(([selectionData, questions, files]) => {
                        resolve({
                            token: selectionData.token,
                            campaignQuestions: questions,
                            files: files || [],
                        });
                    })
                    .catch(reject);
            } else {
                resolve({});
            }
        });

    afterSave = ({ data, result }) =>
        new Promise((resolve) => {
            resolve(data);

            // Upload new files
            if (data.files && data.files.length > 0) {
                const files = data.files.filter((file) => !file.id);
                this.context.store.dispatch(
                    this.context.actions.DocumentActions.addMultipleDocumentsToUpload(
                        files,
                        '-1',
                        CAMPAIGNS.entity,
                        data.id || result.id,
                    ),
                );
            }

            // Remove removed files
            if (data.removedFiles && data.removedFiles.length > 0) {
                const documentManager = Context.entityManager.getEntitiesManager(DOCUMENTS);
                data.removedFiles.forEach((file) => {
                    documentManager.deleteDocumentUnSigned(
                        file,
                        () => {},
                        (err) => {
                            console.error(err);
                        },
                    );
                });
            }
        });

    beforeSave = (schema, data) => {
        // Sending robust data to backend...
        if (data.campaignQuestions) {
            data.campaignQuestions.forEach((question) => {
                const type = question.properties.type;
                if (!['singleValueList', 'multipleValueList'].includes(type)) {
                    question.properties.custom = false;
                }
            });
        }

        data.campaignEndDate = moment(data.campaignEndDate).endOf('day').milliseconds(0);
        data.campaignStartDate = moment(data.campaignStartDate).startOf('day').milliseconds(0);

        return data;
    };

    getExtraFieldSchema = (success, error) => {
        this.context.extraFieldManager.getExtraFieldSchema(
            CAMPAIGNS,
            (schema) => {
                let resultSchema = Map();
                schema.forEach((value) => {
                    resultSchema = resultSchema.set(value['-id'], value);
                });
                success(resultSchema);
            },
            error,
        );
    };

    getFilterSchema = () => FilterSchema;

    getAccounts = (id) =>
        new Promise((resolve, reject) => {
            CampaignsService.getAccounts(id)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });

    getQuestions = (id, filters) =>
        new Promise((resolve, reject) => {
            CampaignsService.getQuestions(id, filters)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });

    getDocuments = (id) =>
        new Promise((resolve, reject) => {
            if (!id) return resolve();
            this.context.domainManager.getDocumentsEntity(CAMPAIGNS, id, '-1', resolve, reject);
        });

    readCampaignNotifications = (ids) => CampaignsService.readNotifications(ids);

    // Method to control wether a campaign is a draft or not in order to adapt the route
    // and remove id if necessary
    backRoute = (entityCrudActive) => {
        const statusId = entityCrudActive.data.statusId;
        const id = entityCrudActive.data.id;
        [1, 3].includes(statusId) ? ensureRoute(backRoute(id)) : ensureRoute(backRoute());
    };

    afterCrudClose = () => {
        this.resetRelatedCompanies();
    };
}
