import React, { memo, useMemo, useRef, useState, useCallback, useEffect, useContext } from 'react';
import { Text, Popover, Spacer, SearchBar, Tabs, Tooltip, Icon, useTheme } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import { getDashboards } from 'services/Dashboards';
import { getLiteral } from 'utils/getLiteral';
import { removeAccents } from 'utils/strings';
import AbsoluteError from 'components/AbsoluteError';
import AbsoluteEmpty from 'components/AbsoluteEmpty';

import { DashboardContext } from './DashboardContext';

const DASHBOARD_TABS = [
    {
        label: 'label_all_dashboards',
        key: 'all',
        icon: 'apps',
    },
    {
        label: 'label_dashboards',
        key: 'dashboards',
        icon: 'chart',
        permission: 'dashboardPermission',
    },
    {
        label: 'title_sales_force',
        key: 'sfm',
        icon: 'salesForce',
        permission: 'sfmPermission',
    },
];

const AddDashboardPopover = memo(({ placement, onAdd, children }) => {
    const theme = useTheme();
    const timer = useRef();
    const { dashboards } = useContext(DashboardContext);
    const [dashboardList, setDashboardList] = useState();
    const [recentlyDashboardList, setRecentlyDashboardList] = useState();
    const [searchText, setSearchText] = useState();
    const [activeTab, setActiveTab] = useState(DASHBOARD_TABS[0].key);
    const [error, setError] = useState(false);
    const permissions = useSelector((state) => ({
        sfmPermission: state.config?.permission?.sfm,
        dashboardPermission:
            state.config?.permission?.analyticsPro && state.config?.userData?.lookerAnalyticsViewer,
    }));

    useEffect(() => {
        setError(false);
        getDashboards()
            .then((result) => {
                setRecentlyDashboardList(
                    result
                        .filter(
                            (dashboard) => dashboard.dateUsed && dashboard?.type?.value !== 'SFM',
                        )
                        ?.filter((dashboard) => {
                            // Exclude current dashboard tabs
                            return !dashboards.find((d) => d.id === dashboard.id);
                        })
                        .map((dashboard) => {
                            if (dashboard?.type?.value === 'SFM')
                                dashboard.title = getLiteral(dashboard.title);
                            return dashboard;
                        })
                        .sort((a, b) => new Date(b.dateUsed) - new Date(a.dateUsed))
                        .slice(0, 4),
                );
                setDashboardList(
                    result
                        .map((dashboard) => {
                            if (dashboard?.type?.value === 'SFM')
                                dashboard.title = getLiteral(dashboard.title);
                            return dashboard;
                        })
                        .sort((a, b) => a?.title?.localeCompare(b?.title)),
                );
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            });
    }, [dashboards]);

    const onChangeSearch = useCallback((event) => {
        const text = event?.target?.value || '';
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setSearchText(text);
        }, 200);
    }, []);

    const getRow = useCallback(
        (dashboard) => {
            return (
                <div
                    className="fm-dashboards-tabs__add-popover__row"
                    onClick={() => onAdd(dashboard)}
                >
                    <div className="fm-dashboards-tabs__add-popover__row-title">
                        <Icon name={dashboard?.type?.value === 'SFM' ? 'salesForce' : 'chart'} />
                        <Text isTruncated useTooltip>
                            {dashboard.title}
                        </Text>
                    </div>
                    {dashboard?.owner && (
                        <Text
                            className="fm-dashboards-tabs__add-popover__row-owner"
                            type="caption"
                            color="neutral700"
                            isTruncated
                            useTooltip
                        >
                            {dashboard?.owner?.value}
                        </Text>
                    )}
                </div>
            );
        },
        [onAdd],
    );

    const list = useMemo(() => {
        if (error) return null;
        const cleanText = removeAccents(searchText);
        return dashboardList
            ?.filter((dashboard) => {
                return !recentlyDashboardList.find((d) => d.id === dashboard.id);
            })
            ?.filter((dashboard) => {
                // Filtering by tab type
                switch (activeTab) {
                    case 'dashboards':
                        return ['ForceBI', 'Metabase'].includes(dashboard?.type?.value);
                    case 'sfm':
                        return 'SFM' === dashboard?.type?.value;
                    case 'all':
                    default:
                        return true;
                }
            })
            ?.filter((dashboard) => {
                // Exclude current dashboard tabs
                return !dashboards.find((d) => d.id === dashboard.id);
            })
            ?.filter((dashboard) => {
                // Filtering by search text
                const cleanTitle = removeAccents((dashboard?.title || '').toLowerCase());
                return cleanTitle.includes(cleanText);
            })
            .map(getRow);
    }, [activeTab, dashboardList, dashboards, error, getRow, recentlyDashboardList, searchText]);

    const content = useMemo(() => {
        const showEmpty = !list?.length && !error;

        return (
            <div className="fm-dashboards-tabs__add-popover__content">
                <div className="fm-dashboards-tabs__add-popover__header">
                    <div className="fm-dashboards-tabs__add-popover__title">
                        <Text type="subtitle">{getLiteral('title_add_tab_dashboard')}</Text>
                    </div>
                    <Spacer y={3} />
                    <SearchBar
                        placeholder={getLiteral('placerholder_search_dashboards')}
                        useAsSimpleSearch={true}
                        onChange={onChangeSearch}
                    />
                </div>
                <div className="fm-dashboards-tabs__add-popover__tabs">
                    <Tabs
                        onChange={setActiveTab}
                        activeKey={activeTab}
                        tabs={DASHBOARD_TABS?.filter(
                            (tab) => !tab.permission || permissions[tab.permission],
                        ).map((tab) => ({
                            key: tab.key,
                            title: (
                                <Tooltip
                                    key={tab.key}
                                    placement="top"
                                    content={getLiteral(tab.label)}
                                >
                                    <span>
                                        <Icon
                                            className="fm-dashboards-tabs__add-popover__tabs__icon"
                                            name={tab.icon}
                                            size="large"
                                            color={
                                                activeTab === tab.key
                                                    ? theme.colors.neutral900
                                                    : theme.colors.neutral600
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            ),
                        }))}
                    />
                </div>
                <div className="fm-dashboards-tabs__add-popover__list">
                    {activeTab !== 'sfm' && (
                        <>
                            {!!recentlyDashboardList?.length && !!recentlyDashboardList?.length && (
                                <>
                                    <Text type="subtitle">
                                        {getLiteral('title_recently_viewed')}
                                    </Text>
                                    <Spacer y={1} />
                                </>
                            )}
                            {recentlyDashboardList?.map(getRow)}
                            {!!recentlyDashboardList?.length && !!dashboardList?.length && (
                                <>
                                    <Spacer y={1} />
                                    <Text type="subtitle">
                                        {getLiteral('label_all_dashboards')}
                                    </Text>
                                    <Spacer y={1} />
                                </>
                            )}
                        </>
                    )}
                    {list}
                    {showEmpty && (
                        <AbsoluteEmpty
                            title={getLiteral('label_empty_screen_add_dashboards')}
                            subtitle={getLiteral('label_empty_screen_add_dashboard_desc')}
                            size="popover"
                        />
                    )}
                    {error && <AbsoluteError size="popover" />}
                </div>
            </div>
        );
    }, [
        activeTab,
        dashboardList?.length,
        error,
        getRow,
        list,
        onChangeSearch,
        permissions,
        recentlyDashboardList,
        theme.colors.neutral600,
        theme.colors.neutral900,
    ]);

    return (
        <Popover
            className="fm-dashboards-tabs__add-popover"
            placement={placement}
            content={content}
        >
            {children}
        </Popover>
    );
});

export default AddDashboardPopover;
