import React, { memo, useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Input } from 'hoi-poi-ui';
import { UsersGroups } from '@web/web5';

import { UserGroupService } from 'services';
import * as ServerListActions from 'actions/ServerList';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

import MultiSelect from 'containers/components/Fields/MultiSelect';

import './usersModalStyles.scss';

function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

const initialState = {
    data: {
        users: [],
        name: '',
    },
    errors: {},
    isOpen: false,
    isLoading: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                data: action.data || initialState.data,
                errors: {},
                isOpen: true,
                isLoading: false,
            };
        case 'changeUsers':
            return {
                ...state,
                data: {
                    ...state.data,
                    users: action.data,
                },
            };
        case 'changeName':
            return {
                ...state,
                data: {
                    ...state.data,
                    name: action.data,
                },
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            throw new Error('No action provided');
    }
}

const SendToField = ({ getList, onChange, value, ...props }) => {
    const [modalState, dispatch] = useReducer(reducer, initialState);
    const [options, setOptions] = useState([]);
    const [disabledUsers, setDisabledUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const loadOptions = useCallback(() => {
        setIsLoading(true);
        Promise.all([UserGroupService.getGroups('looker'), UserGroupService.getEntities('looker')])
            .then(([groups, entities]) => {
                if (entities?.length)
                    setDisabledUsers(
                        entities.reduce((obj, entity) => {
                            obj[entity.id] = !entity.isActive;
                            return obj;
                        }, {}),
                    );
                setOptions(
                    groups.map((group) => ({
                        label: group.name,
                        value: group.id,
                    })),
                );
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const onConfirm = useCallback(() => {
        let group = {
            name: modalState?.data.name,
            type: 'looker',
            entities: modalState.data.users.map((user) => user.id),
        };
        dispatch({ type: 'setLoading', isLoading: true });
        UserGroupService.createGroup(group)
            .then((result) => {
                successToast({
                    text: getLiteral('succes_entitycreatedsuccessfully'),
                });
                dispatch({ type: 'close' });
                onChange([
                    ...(value || []),
                    {
                        label: modalState?.data.name,
                        value: result.code,
                    },
                ]);
                loadOptions();
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    text: getLiteral('label_failed_create'),
                });
            })
            .finally(() => {
                dispatch({ type: 'setLoading', isLoading: true });
            });
    }, [loadOptions, modalState.data.name, modalState.data.users, onChange, value]);

    const onCancel = useCallback(() => dispatch({ type: 'close' }), []);
    const onChangeUsers = useCallback((data) => dispatch({ type: 'changeUsers', data }), []);
    const onChangeName = useCallback((data) => dispatch({ type: 'changeName', data }), []);

    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    const actions = useMemo(
        () => [
            {
                label: getLiteral('action_analytics_pro_create_sender_group'),
                onClick: () => dispatch({ type: 'init' }),
                iconType: 'plus',
            },
        ],
        [],
    );

    const overrides = useMemo(
        () => ({
            'react-select': {
                isLoading,
            },
        }),
        [isLoading],
    );

    const modalContentOverride = useMemo(
        () => ({
            content: {
                className: 'fm-users-groups-modal__content',
            },
        }),
        [],
    );

    return (
        <>
            <MultiSelect
                actions={actions}
                options={options}
                onChange={onChange}
                value={value}
                {...props}
                overrides={overrides}
            />
            <Modal
                width="752px"
                className="fm-users-groups-modal__container"
                overlayClassName="fm-users-groups-modal__overlay"
                useCornerClose={false}
                isOpen={modalState.isOpen}
                onCancel={onCancel}
                onConfirm={onConfirm}
                confirmText={getLiteral('action_save')}
                cancelText={getLiteral('action_cancel')}
                isConfirmLoading={modalState.isLoading}
                title={getLiteral('action_analytics_pro_create_sender_group')}
                useContentStaticHeight
                overrides={modalContentOverride}
            >
                <div className="fm-crud-form fm-crud-form--version-2">
                    <div className="fm-crud-form-tab">
                        <Input
                            label={getLiteral('label_custom_group_add_group_name')}
                            placeholder={getLiteral('placeholder_text_field')}
                            onChange={onChangeName}
                            value={modalState?.data.name}
                            error={modalState.errors.name}
                            type="text"
                            isFullWidth
                            isRequired
                        />
                        <UsersGroups
                            addedUsers={modalState.data.users}
                            onChange={onChangeUsers}
                            disabledUsers={disabledUsers}
                            //leftInfoMessage={getLiteral('cfm_label_no_users_added_des')}
                            //rightInfoMessage={getLiteral('cfm_label_users_description_affected')}
                            //noUserDescription={getLiteral('cfm_label_users_description')}
                        ></UsersGroups>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default memo(connect(null, mapDispatchToProps)(SendToField));
