import React, { memo, useCallback, useState, useRef } from 'react';
// import { Button, Icon } from 'hoi-poi-ui';
// import Context from 'managers/Context';

import { ANALYTICS_REPORT } from 'constants/Entities';

import EntityCrud from 'containers/components/EntityCrud';
import EntityCrudWrapper from 'containers/components/EntityCrud/EntityCrudWrapper';
import EntityCrudHeader from 'containers/components/EntityCrud/EntityCrudHeader';
import EntityCrudContent from 'containers/components/EntityCrud/EntityCrudContent';
import EntityCrudTab from 'containers/components/EntityCrud/EntityCrudTab';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

import { hashHistory } from 'utils/routes';

import 'containers/components/EntityCrud/index.scss';

const DashboardForm = ({ refresh }) => {
    // const schedulingTabRef = useRef();
    // const [schedulingActive, setSchedulingActive] = useState(false);
    const schedulingRef = useRef({});
    // const [scheduling, setScheduling] = useState(null);

    const onReady = useCallback((data) => {
        if (data?.scheduling?.id) {
            schedulingRef.current = data.scheduling;
            //setScheduling(data.scheduling);
            //setSchedulingActive(!!data.scheduling);
        } else {
            schedulingRef.current = {};
            //setScheduling({});
            //setSchedulingActive(false);
        }
    }, []);

    /*const changeFieldScheduling = useCallback(
        (changeField) => (field) => {
            return (value) => {
                let newScheduling = {
                    ...schedulingRef.current,
                    [field]: value,
                };
                // We need a flatten object for error validation and dynamic functionality
                changeField()({
                    [field]: value,
                    scheduling: newScheduling,
                });
                schedulingRef.current = newScheduling;
                setScheduling(newScheduling);
            };
        },
        [],
    ); */

    const getCrudTitle = useCallback((loading, id) => {
        if (loading) return null;
        return !id
            ? getLiteral('label_analytics_pro_new_report')
            : getLiteral('label_analytics_pro_edit_report');
    }, []);

    const handleOnSave = useCallback(
        (result, data) => {
            let toastLiteral = 'succes_entitycreatedsuccessfully';
            if (data.id) toastLiteral = 'succes_entityupdatedsuccessfully';
            successToast({
                text: getLiteral(toastLiteral),
            });
            if (result.code && !data.id)
                hashHistory.push(`/metrics/dashboards/view/${result.code}`);
            else refresh && refresh();
        },
        [refresh],
    );

    const handleOnErrorSave = useCallback((result, error) => {
        let toastLiteral = 'label_failed_create';
        if (result?.data?.id) toastLiteral = 'label_failed_update';
        if (error?.serverError?.includes('already exists'))
            toastLiteral = 'label_failed_create_already_exists_name';
        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    /*
    const onSchedulingTab = useCallback(
        (changeField) => {
            return () => {
                setSchedulingActive(!schedulingActive);

                if (!schedulingActive) {
                    setTimeout(() => {
                        Context.serverListManager
                            .getList('tblTimezones')
                            .then((result) => {
                                const timezone = result?.find(
                                    (item) => item.value === Context?.config?.userData?.idTimeZone,
                                );
                                const defaultScheduling = { timezone };
                                changeField()({
                                    schedulingOpen: true,
                                    timezone,
                                    scheduling: defaultScheduling,
                                });
                                setScheduling(defaultScheduling);
                                schedulingRef.current = defaultScheduling;
                            })
                            .catch(console.error);
                        schedulingTabRef.current.scrollIntoView({ behavior: 'smooth' });
                    });
                }
            };
        },
        [schedulingActive],
    ); */

    /* const onDeleteSchedulingTab = useCallback((changeField) => {
        return () => {
            setSchedulingActive(false);
            changeField()({
                name: null,
                schedule_type: null,
                schedule_day: null,
                schedule_hour: null,
                environments: [],
                scheduling: null,
            });
            schedulingRef.current = null;
            setScheduling(null);
        };
    }, []); */

    /* const deleteSchedulingTabIcon = useCallback(
        (changeField) => {
            return (
                <Icon
                    className="fm-crud-form__button-tab-icon"
                    name="delete"
                    size="large"
                    onClick={onDeleteSchedulingTab(changeField)}
                ></Icon>
            );
        },
        [onDeleteSchedulingTab],
    ); */

    return (
        <div className="dashboard-crud-container">
            <EntityCrud
                entity={ANALYTICS_REPORT}
                hasCrudInDetail={false}
                onReady={onReady}
                onSave={handleOnSave}
                onErrorSave={handleOnErrorSave}
                width="500px"
            >
                {({
                    schema,
                    data,
                    configMap,
                    onClose,
                    onSave,
                    loading,
                    loadingSave,
                    renderLoader,
                    changeField,
                    errors,
                    firstErrorField,
                    dynamicHiddenMap,
                }) => (
                    <EntityCrudWrapper version={2}>
                        <EntityCrudHeader
                            version={2}
                            onCancel={onClose}
                            onSave={onSave}
                            loading={loading}
                            loadingSave={loadingSave}
                            title={getCrudTitle(loading, (data && data.id) || '')}
                            cancelText={getLiteral('action_cancel')}
                            saveText={getLiteral('action_save')}
                        />
                        <EntityCrudContent version={2}>
                            {loading && renderLoader()}
                            {!loading && (
                                <>
                                    <div className="fm-crud-form fm-crud-form--version-2">
                                        <EntityCrudTab
                                            key="tab-general"
                                            tab={schema[0]}
                                            indexTab={0}
                                            data={data}
                                            configMap={configMap}
                                            changeField={changeField}
                                            errors={errors}
                                            firstErrorField={firstErrorField}
                                            dynamicHiddenMap={dynamicHiddenMap}
                                            version={2}
                                        />
                                        {/*{!schedulingActive && (
                                            <Button
                                                className="fm-crud-form__button-tab"
                                                type="secondary"
                                                isFullWidth
                                                onClick={onSchedulingTab(changeField)}
                                            >
                                                {getLiteral('label_analytics_pro_add_scheduling')}
                                            </Button>
                                        )} */}
                                        {/* {schedulingActive && (
                                            <EntityCrudTab
                                                key={'tab-scheduling'}
                                                innerRef={schedulingTabRef}
                                                tab={schema[1]}
                                                indexTab={1}
                                                data={scheduling || {}}
                                                configMap={configMap}
                                                changeField={changeFieldScheduling(changeField)}
                                                errors={errors}
                                                firstErrorField={firstErrorField}
                                                dynamicHiddenMap={dynamicHiddenMap}
                                                version={2}
                                                postComponentHeader={deleteSchedulingTabIcon(
                                                    changeField,
                                                )}
                                            />
                                        )} */}
                                    </div>
                                </>
                            )}
                        </EntityCrudContent>
                    </EntityCrudWrapper>
                )}
            </EntityCrud>
        </div>
    );
};

export default memo(DashboardForm);
