import React, { memo, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import Modal from 'components/ModalHoi';
import { Divider, Input } from 'hoi-poi-ui';
import classnames from 'classnames';
import { createTemplate, updateTemplate } from 'services/MailingService';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { logEvent } from 'utils/tracking';
import EditorToolbar from '../../EditorToolbar';
import EditorMin from '../../EditorMin';
import { EmailEditorContext } from '../../../EmailEditorContext';

const TemplateFormModal = memo(({ onRef, onSave, setPopoverVisible }) => {
    const { isTemplatesFullscreen, onTemplatesFullscreen } = useContext(EmailEditorContext);
    const [editor, setEditor] = useState(null);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({});
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onRef &&
            onRef({
                open(template = {}) {
                    // Reset and defaults
                    setLoading(false);
                    setForm({
                        id: template.id,
                        name: template.name,
                        template: template.template,
                    });
                    setOpen(true);
                },
            });
    }, [onRef]);

    const onInnerCancel = useCallback(() => {
        setOpen(false);
        setPopoverVisible(true);
        setTimeout(() => onTemplatesFullscreen(false), 500);
    }, [onTemplatesFullscreen, setPopoverVisible]);

    const saveForm = useCallback(() => {
        logEvent({
            event: 'emails',
            submodule: 'templates',
            functionality: 'create',
        });

        // Checking errors
        let error;
        if (!form.name) error = getLiteral('helptext_required_field');
        else if (form.name?.length > 100)
            error = getLiteralWithParameters('error_text_too_long', [100]);

        if (error) {
            return setError(error);
        }

        setLoading(true);
        const call = form.id ? () => updateTemplate(form.id, form) : () => createTemplate(form);
        return call()
            .then(() => {
                successToast({
                    title: getLiteral(
                        form.id
                            ? 'title_success_template_updated'
                            : 'title_success_template_created',
                    ),
                    text: getLiteral(
                        form.id
                            ? 'label_success_template_updated'
                            : 'label_success_template_created',
                    ),
                });
                setOpen(false);
                setPopoverVisible(true);
                setTimeout(() => onTemplatesFullscreen(false), 500);
                return form;
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    title: getLiteral(
                        form.id ? 'title_error_template_updated' : 'title_error_template_created',
                    ),
                    text: getLiteral(
                        form.id ? 'title_error_template_updated' : 'label_error_template_created',
                    ),
                });
            })
            .finally(() => setLoading(false));
    }, [form, onTemplatesFullscreen, setPopoverVisible]);

    const onInnerSave = useCallback(() => {
        saveForm().then(() => onSave(form, false));
    }, [form, onSave, saveForm]);

    const onInnerSaveAndApply = useCallback(() => {
        saveForm().then(() => onSave(form, true));
    }, [form, onSave, saveForm]);

    const onChange = useCallback((field, value) => {
        setError();
        setForm((currentForm) => ({ ...currentForm, [field]: value === '<p></p>' ? '' : value }));
    }, []);

    const onEditorChange = useCallback((value) => onChange('template', value), [onChange]);

    const modalProps = useMemo(() => ({ middleButton: { type: 'primary' } }), []);

    const overlayClassName = classnames('fm-ee__email-editor-overlay', {
        'fm-ee__email-editor-overlay--fullscreen': isTemplatesFullscreen,
    });

    return (
        <Modal
            className="fm-template-mailing-modal"
            overlayClassName={overlayClassName}
            isOpen={open}
            confirmText={getLiteral('action_save_and_apply')}
            middleButtonText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            title={getLiteral('title_create_template')}
            size="full"
            onRequestClose={onInnerCancel}
            onConfirm={onInnerSaveAndApply}
            onMiddleButton={onInnerSave}
            onCancel={onInnerCancel}
            isMiddleButtonDisabled={!form?.name}
            isMiddleButtonLoading={loading}
            isConfirmDisabled={!form?.name}
            isConfirmLoading={loading}
            overrides={modalProps}
        >
            <Input
                label={getLiteral('label_template_name')}
                placeholder={getLiteral('placeholder_type_name_template')}
                onChange={(value) => onChange('name', value)}
                value={form?.name}
                type="text"
                error={error}
                isRequired
            />
            <div className="fm-template-mailing-modal__editor">
                <EditorToolbar
                    editor={editor}
                    isFullscreen={isTemplatesFullscreen}
                    onFullscreen={onTemplatesFullscreen}
                />
                <Divider />
                <EditorMin
                    content={form?.template}
                    onChange={onEditorChange}
                    editor={editor}
                    setEditor={setEditor}
                />
            </div>
        </Modal>
    );
});

export default memo(TemplateFormModal);
