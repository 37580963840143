import React, { memo, useEffect, useState, useCallback } from 'react';

import { LookerService } from 'services';
import EntityDeleteWithText from 'containers/components/modals/EntityDeleteWithText';

const MetricDashboardDeleteModal = memo(({ onConfirm, onCancel, data }) => {
    const [labels, setLabels] = useState();
    const innerOnConfirm = useCallback(() => {
        let call = LookerService.deleteDashboard;
        if (data.isFolder) call = LookerService.deleteFolder;
        if (data.isWidget) call = LookerService.deleteWidget;

        return call(data.id).then(() => {
            onConfirm();
        });
    }, [data, onConfirm]);

    useEffect(() => {
        if (!data) return;
        let literals = {
            title: 'label_analytics_pro_delete_folder',
            label: 'label_analytics_pro_delete_folder_explanation',
            advice: 'label_analytics_pro_delete_folder_alert',
        };
        if (data?.isDashboard) {
            literals = {
                title: 'label_analytics_pro_delete_report',
                label: 'label_analytics_pro_delete_dashboard_explanation',
                advice: 'label_analytics_pro_delete_dashboard_alert',
            };
        } else if (data?.isWidget) {
            literals = {
                title: 'label_analytics_pro_delete_widget',
                label: 'label_analytics_pro_delete_widget_explanation',
                advice: 'label_analytics_pro_delete_widget_alert',
            };
        }
        setLabels(literals);
    }, [data]);

    return (
        <EntityDeleteWithText
            isOpen={!!data}
            deleteDashboard={LookerService.deleteDashboard}
            deleteFolder={LookerService.deleteFolder}
            deleteWidget={LookerService.deleteWidget}
            onConfirm={innerOnConfirm}
            onCancel={onCancel}
            {...labels}
        />
    );
});

export default MetricDashboardDeleteModal;
