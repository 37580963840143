import { FOLDER_DOCUMENTS, DOCUMENTS } from 'constants/Entities';
import Context from 'managers/Context';
import { DocumentSignedFormModel } from '../../models';
import { getLiteral } from 'utils/getLiteral';
import { buildTreeFromFlatObject, mapTree, getTreeFamily } from 'utils/treeNavigation';

export default class DocumentsManager {
    createEntity(folder, success, error) {
        let newFolder = {
            strFolderName: folder.title,
            idParent: folder.parentId,
            blnRestrictEnviroments: false,
            intSecurityLevel: folder.securityLevel || 0,
            strIdEnvironments: folder.environment.join(';'),
        };

        if (folder.entityType) {
            newFolder.idObjectType = folder.entityType.objectTypeId;
        }
        if (folder.idEntity) {
            newFolder.idEntity = folder.idEntity;
        }

        newFolder = ParseEntityCrud(newFolder);
        Context.domainManager.createEntity(FOLDER_DOCUMENTS.entity, newFolder, success, error);
    }

    createEntityFolder(folderParentId, folderName, entityType, idEntity, success, error) {
        const crudDefault = ParseEntityCrud({
            strFolderName: folderName,
            idParent: folderParentId,
            idObjectType: entityType.objectTypeId,
            idEntity,
            blnRestrictEnviroments: false,
            intSecurityLevel: 2,
        });
        Context.domainManager.createEntityFolder(
            FOLDER_DOCUMENTS.entity,
            crudDefault,
            success,
            error,
        );
    }

    createLink(name, link, folder, success, error) {
        const crudDefault = ParseEntityCrud({
            strFileDescription: name,
            strLink: link,
            idLibraryFolder: folder,
            blnIsSynchronized: false,
            strExtension: '',
        });
        Context.domainManager.createEntity(`${DOCUMENTS.entity}/link`, crudDefault, success, error);
    }

    updateEntity(folder, name, level, idEnviroment, success, error) {
        let crudDefault;
        if (folder.is_folder === '1') {
            crudDefault = ParseEntityCrud({
                strFolderName: name, // carpeta
                idParent: folder.node_id, // carpeta
                intSecurityLevel: level, // carpeta
                strIdEnvironments: idEnviroment, // carpeta
            });
        } else {
            crudDefault = ParseEntityCrud({
                strFileDescription: name,
                idLibraryFolder: folder.node_id,
                blnInPublicDocumentLibrary: false,
            });
        }
        const entityConfig = folder.is_folder === '1' ? FOLDER_DOCUMENTS : DOCUMENTS;
        this.context.domainManager.updateEntity(
            entityConfig.entity,
            folder.id,
            crudDefault,
            (dataSuccess) => {
                success(dataSuccess);
                this.context.handlers.notify(DOCUMENTS.entity);
            },
            error,
        );
    }

    updateLink(name, link, entity, success, error) {
        const crudDefault = ParseEntityCrud({
            strFileDescription: name,
            strLink: link,
            idLibraryFolder: entity.node_id,
            blnIsSynchronized: false,
            strExtension: '',
            blnInPublicDocumentLibrary: false,
        });
        this.context.domainManager.updateEntity(
            `${DOCUMENTS.entity}/link`,
            entity.id,
            crudDefault,
            success,
            error,
        );
    }

    deleteEntity(folder, success, error) {
        if (!folder) return;
        if (
            folder.provideridentifier_file &&
            folder.provideridentifier_file !== '' &&
            folder.is_folder === '0'
        ) {
            this.deleteDocumentProvider(folder, success, error);
        } else {
            this.deleteDocumentUnSigned(folder, success, error);
        }
    }

    deleteDocumentUnSigned(folder, success, error) {
        const entityConfig = folder.is_folder === '1' ? FOLDER_DOCUMENTS : DOCUMENTS;
        Context.domainManager.deleteEntity(
            entityConfig.entity,
            folder.id,
            (result) => {
                if (
                    (result.Deleted && result.Deleted.num === '1') ||
                    result.result === 'ok' ||
                    (result.message && result.message === 'ok')
                ) {
                    Context.handlers.notify(DOCUMENTS.entity);
                    success(result);
                } else {
                    error(result);
                }
            },
            error,
        );
    }

    deleteDocument = (id) =>
        new Promise((resolve, reject) => {
            Context.domainManager.deleteEntity(
                DOCUMENTS.entity,
                id,
                (result) => {
                    if (
                        (result.Deleted && result.Deleted.num === '1') ||
                        result.result === 'ok' ||
                        (result.message && result.message === 'ok')
                    ) {
                        resolve();
                    } else {
                        reject();
                    }
                },
                reject,
            );
        });

    deleteDocumentProvider(folder, success, error) {
        Context.domainManager.deleteDocumentProvider(
            folder.provideridentifier_file,
            (result) => {
                success(result);
                Context.handlers.notify(DOCUMENTS.entity);
            },
            error,
        );
    }

    getFolders(success, error) {
        Context.domainManager.getFoldersDocuments((response) => {
            const data = response.List || [];
            const base = { id: '-1', name: getLiteral('title_documents'), children: [] };
            data.push(base);
            let tree = { ...base };
            if (response?.List?.length > 0) {
                tree = buildTreeFromFlatObject(data);
            }
            let finalTree = {};
            mapTree(finalTree, tree);
            let family = {};
            getTreeFamily(finalTree, finalTree['-1'], family);
            success(finalTree, family);
        }, error);
    }

    transformDataForEntityList(data) {
        if (data.List) {
            data.Data = [...data.List];
            delete data.List;
            if (!data.MetaData) {
                data.MetaData = {
                    totalRows: data.DocumentCount,
                };
            }
        } else {
            data.Data = [];
            data.MetaData = { totalRows: 0 };
        }
        return data;
    }

    getDownloadDocumentLink(file, response, error) {
        Context.domainManager.getDocumentLink(
            DOCUMENTS.entity,
            file.Id || file.id,
            (result) => {
                response(`${Context.constants.getUrlDownloadDocument()}?token=${result.token}`);
            },
            error,
        );
    }

    _urlFixer(url) {
        return url.match(/^http/) != null
            ? url
            : url.match(/^www/) != null
              ? `http://${url}`
              : `http://www.${url}`;
    }

    downloadDocument(file, success, error) {
        file.link.length > 0
            ? window.open(this._urlFixer(file.link), '_blank')
            : this.getDownloadDocumentLink(
                  file,
                  (url) => {
                      saveAs(url, file.file);
                      success();
                  },
                  error,
              );
    }

    uploadFile(file, idNode, entity, entityId, success, error) {
        /*
            idNode could be the folder in files manager or the idEntity, depending on the entity
         */
        if (!file) error(''); // if we answer the question below, we can answer this question
        const filename = file.name;
        let extension = '';
        if (filename) {
            const filenameSplit = filename.split('.');
            extension = filenameSplit[filenameSplit.length - 1];
        }

        let relatedEntityId;
        if (entityId) {
            // Added this to pass the entityId when we add files from a widget.
            relatedEntityId = entityId.toString();
        } else {
            relatedEntityId = entity !== 'documents' && idNode ? idNode.toString() : '-1';
        }

        let currentIdNode;
        if (entity === 'documents' && idNode) {
            currentIdNode = idNode.toString();
        } else if (idNode) {
            // Added this line to contemplate when idNode is the REAL idNode and not entityId or whatever else, like says the comment above.
            // This is for Documents Widget -> add files inside a folder.
            currentIdNode = idNode.toString();
        } else {
            currentIdNode = '-1';
        }

        const parameters = {
            filename,
            idNode: currentIdNode,
            extension,
            relatedEntityId,
            relatedEntityType: entity !== 'documents' ? entity : '',
            idUser: Context.store.getState().config.userData.idUsuario,
            idImplementation: Context.store.getState().config.userData.idImplementacion,
        };

        Context.domainManager.uploadFile(
            entity,
            file,
            parameters,
            (response) => {
                if (response === '') {
                    error(''); // why no message?
                } else {
                    success();
                    Context.handlers.notify(DOCUMENTS.entity);
                }
            },
            error,
        );
    }

    uploadFileWithProgress(file, idNode, entity, idEntity, success, error) {
        if (!file) error('');

        const filename = file.name;
        let extension = '';
        if (filename) {
            const filenameSplit = filename.split('.');
            extension = filenameSplit[filenameSplit.length - 1];
        }

        let relatedEntityId;
        if (idEntity) {
            // Added this to pass the idEntity when we add files from a widget.
            relatedEntityId = idEntity.toString();
        } else {
            relatedEntityId = entity !== 'documents' && idNode ? idNode.toString() : '-1';
        }

        let currentIdNode;
        if (entity === 'documents' && idNode) {
            currentIdNode = idNode.toString();
        } else if (idNode) {
            // Added this line to contemplate when idNode is the REAL idNode and not idEntity or whatever else, like says the comment above.
            // This is for Documents Widget -> add files inside a folder.
            currentIdNode = idNode.toString();
        } else {
            currentIdNode = '-1';
        }

        const parameters = {
            filename,
            idNode: currentIdNode,
            extension,
            relatedEntityId,
            relatedEntityType: entity !== 'documents' ? entity : '',
            idUser: parseInt(Context.store.getState().config.userData.idUsuario, 10),
            idImplementation: parseInt(
                Context.store.getState().config.userData.idImplementacion,
                10,
            ),
        };

        Context.domainManager.uploadFileWithProgress(
            entity,
            parameters,
            (response) => {
                success(response);
            },
            error,
        );
    }

    setFollow(id, follow, isShared = false, success, error) {
        const entityConfig = DOCUMENTS;
        this.context.domainManager.setFollow(
            entityConfig.entity,
            id,
            follow,
            isShared,
            () => {
                success(() => {});
            },
            error,
        );
    }

    getDocumentsEntity(entityType, entityId, idFolder, success, error) {
        Context.domainManager.getDocumentsEntity(entityType, entityId, idFolder, success, error);
    }

    getFormSign(
        entityDependence,
        idEntityDependence,
        documentModel,
        callbackSucces,
        callbackError,
    ) {
        Context.domainManager.getDocumentSignForm(
            documentModel.id,
            (result) => {
                callbackSucces(
                    new DocumentSignedFormModel(
                        entityDependence,
                        idEntityDependence,
                        documentModel,
                        result,
                    ),
                );
            },
            callbackError,
        );
    }

    getDocumentSignStatus(documentModel, callbackSucces, callbackError) {
        Context.domainManager.getDocumentSignStatus(
            documentModel.providerIdentifierFile,
            (result) => {
                callbackSucces(result);
            },
            callbackError,
        );
    }

    setReportsForm(documentSignedFormModel, success, error) {
        Context.domainManager.setReportsForm(
            DOCUMENTS.entity,
            documentSignedFormModel,
            (result) => {
                success(result);
            },
            error,
        );
    }

    shareItem = (file, action, isSigned, sendEmail) => {
        const dispatch = Context.store.dispatch;
        const downloadDocumentLink = Context.actions.DocumentActions.downloadDocumentLink;
        dispatch(downloadDocumentLink(file, sendEmail));
    };

    editItem = (selectedRow, crudAction, elementType) => {
        const dispatch = Context.store.dispatch;
        const showCreateFolder = Context.actions.DocumentActions.showCreateFolder;
        dispatch(
            showCreateFolder(
                true,
                selectedRow.node_id,
                DOCUMENTS,
                selectedRow,
                crudAction,
                elementType,
            ),
        );
    };

    deleteItem = (selectedRow) => {
        const dispatch = Context.store.dispatch;
        const showDeleteFolder = Context.actions.DocumentActions.showDeleteFolder;
        dispatch(showDeleteFolder(true, DOCUMENTS, selectedRow));
    };
}

const ParseEntityCrud = (object) => {
    const result = [];
    for (const name in object) {
        let value = object[name];
        value = value instanceof Array ? value[0].id : typeof value === 'object' ? value.id : value;
        result.push({ name: name.replace('EXTRA_', ''), value: `${value}` });
    }
    return result;
};

function saveAs(uri, filename) {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); // Firefox requires the link to be in the body
        link.download = filename;
        link.href = uri;
        link.click();
        document.body.removeChild(link); // remove the link when done
    } else {
        location.replace(uri);
    }
}
