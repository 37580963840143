import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { Text } from 'hoi-poi-ui';

import EmptyImage from './EmptyImage';

import './styles.scss';

const propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

const AbsoluteEmpty = memo(
    ({
        title = getLiteral('label_page_error_title'),
        subtitle = getLiteral('error_generalerror'),
        img,
        svg,
        size,
    }) => {
        const className = classnames('fm-absolute-empty', {
            'fm-absolute-empty--small': size === 'small',
            'fm-absolute-empty--popover': size === 'popover',
        });

        const titleType = size === 'popover' ? 'subtitle1' : 'h6';
        const subtitleType = size === 'popover' ? 'caption' : 'body';
        const showDefaultSvg = !svg && !img;
        return (
            <div className={className}>
                {!svg && img && <img src={img} />}
                {svg}
                {showDefaultSvg && <EmptyImage />}
                <Text type={titleType}>{title}</Text>
                <Text type={subtitleType} color="neutral700">
                    {subtitle}
                </Text>
            </div>
        );
    },
);

AbsoluteEmpty.propTypes = propTypes;

export default AbsoluteEmpty;
