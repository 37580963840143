import { REPORTS, COMPANIES, OPPORTUNITIES, SALESORDERS, PRODUCTS } from 'constants/Entities';
import { Map } from 'immutable';
import moment from 'moment';
import { ReportSignedFormModel } from 'models';
import { getLiteral } from 'utils/getLiteral';
import { formatDateForServer } from 'utils/dates';
import { getMomentFromDateBackend } from 'utils/dates';
import Context from 'managers/Context';

// TODO try to remove this method that are outside the class
function getNewNode(folders, id, shared) {
    if (shared) {
        folders[-id] = {};
        folders[-id].childNodes = [];
        folders[-id].folder = {};
        folders[-id].isShared = `${shared}`;
        return folders[-id];
    }
    folders[id] = {};
    folders[id].childNodes = [];
    folders[id].folder = {};
    folders[id].isShared = `${shared}`;
    return folders[id];
}

function buildFolders(folders, tree) {
    const node = getNewNode(folders, `${tree.id}`, tree.isShared);
    node.folder.name = tree.name;
    node.folder.id = tree.isShared ? `-${tree.id.toString()}` : tree.id.toString();
    node.folder.isShared = `${tree.isShared}`;
    tree.children.forEach((child) => {
        node.childNodes.push({
            name: child.name,
            id: `${child.id}`,
            node_id: `${node.folder.id}`,
            isShared: `${child.isShared}`,
        });
        buildFolders(folders, child);
    });
}

export default class ReportsManager {
    constructor(actions) {
        this.actions = actions;
    }

    // TODO unify the four methods getReports2
    getReports2(type, idFolder, getFolders, callbackSuccess, callbackError) {
        const result = type ? this.context.cacheManager.getReports(type.entity + idFolder) : '';
        if (result) {
            callbackSuccess(result);
        } else {
            const init = 0;
            const count = 500;
            this.context.domainManager.getReports2(
                init,
                count,
                type.entity,
                idFolder,
                getFolders,
                {},
                (reportList) => {
                    this.context.cacheManager.setReports(type.entity + idFolder, reportList);
                    callbackSuccess(reportList);
                },
                callbackError,
            );
        }
    }

    // TODO unify the four methods getReports2
    getReports2Model(type, idFolder, getFolders, callbackSuccess, callbackError) {
        this.getReports2(
            type,
            idFolder,
            getFolders,
            (reportList) => {
                callbackSuccess(reportList.map((report) => report));
            },
            callbackError,
        );
    }

    // TODO unify the four methods getReports2
    getReports2Map(type, idFolder, getFolders, callbackSuccess, callbackError) {
        this.getReports2(
            type,
            idFolder,
            getFolders,
            (reportList) => {
                callbackSuccess(reportList.map((report) => new Map(report)));
            },
            callbackError,
        );
    }

    // TODO unify the four methods getReports2
    getReports2Entity(type, idType, success, error) {
        const init = 0;
        const count = 500;
        this.context.domainManager.getReports2Entity(
            init,
            count,
            type.entity,
            idType,
            (reportList) => {
                const reports = this.translateData(reportList);
                success(reports);
            },
            error,
        );
    }

    getAllReports(entity, idFolder, params = {}) {
        return new Promise((resolve, reject) => {
            const init = 0;
            const count = 500;
            this.context.domainManager.getReports2(
                init,
                count,
                entity.entity,
                idFolder,
                true,
                params,
                (reports) => {
                    const finalReports = reports
                        ?.map((report) => {
                            return {
                                ...report,
                                isShared: report.Shared === '1',
                                isReportFolder: report.isFolder === '1',
                                isReport: report.isFolder === '0',
                                isTable:
                                    report.isFolder === '0' && report.isCrystal === '0'
                                        ? true
                                        : false,
                                dateCreated: getMomentFromDateBackend(report.fcreado)
                                    .utc()
                                    .format(),
                                name: report.nombre,
                            };
                        })
                        ?.sort((a, b) => a.name.localeCompare(b.name));
                    resolve(finalReports || []);
                },
                reject,
            );
        });
    }

    transformDataForEntityList(data) {
        if (data.reports) {
            data.Data = this.translateData(data.reports);
            delete data.reports;
            if (!data.MetaData) {
                data.MetaData = {
                    totalRows: data.Data.length,
                };
            }
        } else {
            data.Data = [];
            data.MetaData = { totalRows: 0 };
        }
        return data;
    }

    translateData(data) {
        const newDataList = data
            .map((current) => {
                let unit = {
                    ...current,
                    isShared: current.Shared === '1',
                    name: current.nombre,
                    node_id: current.idnode,
                };
                delete unit.Shared;
                delete unit.nombre;
                delete unit.idnode;
                return unit;
            })
            .sort((a, b) => a.name.localeCompare(b.name));
        return newDataList;
    }

    getReportLink(idReport, parameters, entityType, entityId, success, error) {
        let documentFormat = 'pdf';

        let realParameters = { ...parameters, ...this.injectEntityParameter(entityType, entityId) };
        if (realParameters.hasOwnProperty('documentFormat')) {
            documentFormat = realParameters.documentFormat;
            delete realParameters.documentFormat;
        }

        this.context.domainManager.getReportLink(
            idReport,
            realParameters,
            documentFormat,
            entityType.entity,
            entityId,
            (token) => {
                if (!token.token || token === 'Error generating report') {
                    // this is a hardcoded text from backend
                    error();
                } else {
                    success(
                        `${this.context.constants.getUrlDownloadDocument()}?token=${token.token}`,
                        token.token,
                    );
                }
            },
            error,
        );
    }

    getTableReportLink(idReport, parameters, success, error) {
        const locale = this.context.store.getState().config.userData.langISODDBB;
        this.context.domainManager.getTableReportLink(
            idReport,
            parameters,
            locale,
            (response) => {
                if (response.substring(0, 7) !== 'FileID:') {
                    error();
                } else {
                    const token = response.substring(8);
                    success(
                        `${this.context.constants.getUrlDownloadDocument()}?token=${token}`,
                        token,
                    );
                }
            },
            error,
        );
    }

    getReportTable(idReport, parameters, success, error) {
        this.context.domainManager.getReportTable(
            idReport,
            parameters,
            (data) => {
                let rows;
                let parsedData;
                try {
                    parsedData = JSON.parse(data);
                } catch (err) {
                    console.error(err);
                    error(data);
                    return;
                }
                rows = parsedData.data.row;
                rows = rows instanceof Array ? rows : [rows];
                // XML parser in GO prefixes xml attributes with '-'
                rows = rows.map((row) => {
                    const obj = {};
                    for (const key in row) {
                        const val = row[key];
                        obj[key[0] === '-' ? key.substring(1) : key] = val;
                    }
                    return obj;
                });

                success({
                    headers: parsedData.headers || [],
                    //rows: rows.map((row) => new Map(row)),
                    rows: rows,
                });
            },
            error,
        );
    }

    // For metric page
    getReportsFolders() {
        return new Promise((resolve, reject) => {
            this.context.domainManager.getReportsFolders((response) => {
                try {
                    const folders = JSON.parse(response).Result;
                    if (folders)
                        folders.name =
                            this.context.store.getState().literal.literals.label_reports || ' ';
                    resolve(folders);
                } catch (e) {
                    reject(e);
                }
            }, reject);
        });
    }

    getFolders(success, error) {
        this.context.domainManager.getReportsFolders((response) => {
            try {
                const res = JSON.parse(response).Result;
                const folders = {};
                buildFolders(folders, res);
                const literal = this.context.store.getState().literal.literals.label_reports;
                folders['-1'].folder.name = literal || ' ';
                success(folders);
            } catch (e) {
                error(e);
            }
        }, error);
    }

    getReportsSearch(search, success, error) {
        const init = 0;
        const count = 500;
        this.context.domainManager.getReportsSearch(
            init,
            count,
            search,
            (reports) => {
                success(reports.map((report) => new Map(report)));
            },
            error,
        );
    }

    getReportsForm(idReport, nameReport, entity, idEntity, success, error, isShared) {
        this.context.domainManager.getReportsForm(
            idReport,
            nameReport,
            entity,
            idEntity,
            (result) => {
                success(new ReportSignedFormModel(idEntity, entity, nameReport, result));
            },
            error,
            isShared,
        );
    }

    getReportParameters = (idReport, isShared, entity = REPORTS, success, error) => {
        this.context.domainManager.getReportParameters(
            idReport,
            isShared,
            (parameters) => {
                this.getParametersSchema(parameters.data, entity).then((data) => {
                    success(data);
                });
            },
            error,
        );
    };

    injectEntityParameter = (entity, idEntity) => {
        let parameter = {};
        switch (entity) {
            case COMPANIES:
                parameter['IdEmpresa'] = idEntity.toString();
                break;
            case OPPORTUNITIES:
                parameter['IdExpediente'] = idEntity.toString();
                break;
            case SALESORDERS:
                parameter['IdSalesOrder'] = idEntity.toString();
                break;
            case PRODUCTS:
                parameter['IdProducto'] = idEntity.toString();
                break;
        }
        return parameter;
    };

    getParametersSchema = (parameters = [], entity) => {
        return new Promise((resolve) => {
            let lists = [];
            let listsPromises = [];
            let schema = [
                {
                    id: 'documentFormat',
                    server: 'documentFormat',
                    description: getLiteral('label_select_report_format'),
                    hint: '',
                    type: 'valueList',
                    table: 'fileFormat',
                    feature: '',
                    value: 'pdf',
                    mandatory: true,
                },
            ];

            for (const parameter of parameters) {
                // prevent show the parameter if we are in the entity itself
                const entityName = entity?.entity?.toLowerCase();
                if (
                    (parameter.Type === 'account' && entityName === 'companies') ||
                    (parameter.Type === 'opportunity' && entityName === 'opportunities') ||
                    (parameter.Type === 'salesorder' && entityName === 'salesorders')
                ) {
                    continue;
                }

                let schemaObject = {
                    id: parameter.Id,
                    server: parameter.Id,
                    description: getLiteral(parameter.Label),
                    hint: '',
                    type: '',
                    table: parameter.List,
                    feature: '',
                    value: parameter.Value,
                    mandatory: parameter.Required,
                };

                switch (parameter.Type) {
                    case 'date':
                        schemaObject.type = 'date';
                        schemaObject.isForStart = parameter.Id === '[FECHADE]';
                        break;
                    case 'user':
                        schemaObject.type = 'fuzzySearch';
                        schemaObject.table = 'users';
                        schemaObject.feature = 'nombre';
                        schemaObject.hint = getLiteral('action_searchusername');
                        if (!schemaObject.value) {
                            const userData = this.context.store.getState().config.userData;
                            schemaObject.value = {
                                value: userData.idUsuario,
                                label: `${userData.nombre} ${userData.apellidos}`,
                            };
                        }
                        break;
                    case 'account':
                        schemaObject.type = 'fuzzySearch';
                        schemaObject.table = 'empresas';
                        schemaObject.feature = 'nombre';
                        schemaObject.hint = getLiteral('action_searchcompany');
                        break;
                    case 'opportunity':
                        schemaObject.type = 'fuzzySearch';
                        schemaObject.table = 'expedientes';
                        schemaObject.feature = '';
                        schemaObject.hint = getLiteral('action_searchopportunity');
                        break;
                    case 'environment':
                    case 'branch':
                        schemaObject.type = 'valueList';
                        schemaObject.table = 'tblSucursales';
                        lists.push({ id: schemaObject.id, table: schemaObject.table });
                        break;
                    case 'singlevaluelist':
                        schemaObject.type = 'valueList';
                        const realName = schemaObject.id
                            .replace(/[\[\]]/gi, '')
                            .replace(' ', '')
                            .toLowerCase();
                        // FM3 backend does not return properly the parameters, so we need to hardcode the loadTable and parents fields to bind it correctly
                        if (parameter.ParameterDescription) {
                            switch (parameter.ParameterDescription.toLowerCase()) {
                                case '[idcountry]':
                                case '[idpais]':
                                    schemaObject.table = 'tblCountries';
                                    schemaObject.parentField = '';
                                    schemaObject.id = 'idcountry'; // backend needs this patch for legacy code
                                    break;
                                case '[idprovincia]':
                                    schemaObject.table = 'tblProvincias';
                                    schemaObject.parentField = 'idcountry';
                                    schemaObject.id = realName;
                                    break;
                                case '[idpoblacion]':
                                    schemaObject.table = 'tblpoblaciones';
                                    schemaObject.parentField = 'idprovincia';
                                    schemaObject.id = realName;
                                    break;
                                default:
                                    schemaObject.id = realName;
                                    lists.push({ id: schemaObject.id, table: schemaObject.table });
                                    break;
                            }
                        } else {
                            lists.push({ id: schemaObject.id, table: schemaObject.table });
                        }
                        if (!schemaObject.value) {
                            schemaObject.value = '-1';
                        }
                        break;
                    case 'tblclevel1':
                        schemaObject.type = 'valueList';
                        schemaObject.table = 'tblclevel1';
                        lists.push({ id: schemaObject.id, table: schemaObject.table });
                        break;
                    case 'tblclevel2':
                        schemaObject.type = 'valueList';
                        schemaObject.table = 'tblclevel2';
                        lists.push({ id: schemaObject.id, table: schemaObject.table });
                        break;
                    case 'tblclevel3':
                        schemaObject.type = 'valueList';
                        schemaObject.table = 'tblclevel3';
                        lists.push({ id: schemaObject.id, table: schemaObject.table });
                        break;
                    case 'tblTiposEmpresa':
                        schemaObject.type = 'valueList';
                        schemaObject.table = 'tblTiposEmpresa';
                        lists.push({ id: schemaObject.id, table: schemaObject.table });
                        break;
                    case 'textbox':
                    default:
                        schemaObject.type = 'textarea';
                        break;
                }
                schema.push(schemaObject);
            }

            lists = [...new Set(lists)]; // remove duplicated values
            lists.map((list) => {
                let promise = Context.store.dispatch(
                    Context.actions.ServerListActions.getList(list.table),
                );
                listsPromises.push(promise);
            });

            Promise.all(listsPromises).then((listsValues) => {
                listsValues.map((listValue, index) => {
                    if (listValue.length >= 2000) {
                        let idField = lists[index].id;
                        let field = schema.find((s) => s.id === idField);
                        field.type = 'fuzzySearch';
                    }
                });
                resolve(schema);
            });
        });
    };

    getParametersDependencies = (schema) => {
        let dependencies = schema.reduce((obj, field) => {
            if (!field.parentField) return obj;
            if (!obj[field.parentField]) {
                obj[field.parentField] = [];
            }
            obj[field.parentField].push(field.id);
            return obj;
        }, {});
        return dependencies;
    };

    getParametersErrors = (schema, data) => {
        let errors = {};
        schema.forEach((field) => {
            // Mandatory
            if (field.mandatory) {
                switch (field.type) {
                    case 'valueList':
                        if (!data[field.id] || data[field.id] === '-1')
                            errors[field.id] = 'mandatory';
                        break;
                    default:
                        if (!data[field.id]) errors[field.id] = 'mandatory';
                        if (Array.isArray(data[field.id]) && data[field.id].length === 0)
                            errors[field.id] = 'mandatory';
                        break;
                }
            }
        });
        return errors;
    };

    getServerCrud = (schema, data) => {
        return schema.reduce((obj, field) => {
            switch (field.type) {
                case 'date':
                    if (data[field.id]) {
                        let momentDate = moment(data[field.id]);
                        if (field.isForStart) {
                            momentDate = momentDate.hour(0).minute(0);
                        } else {
                            momentDate = momentDate.hour(23).minute(30);
                        }
                        obj[field.server] = formatDateForServer(momentDate);
                    } else {
                        obj[field.server] = '';
                    }
                    break;
                case 'fuzzySearch':
                    if (data[field.id] && data[field.id].value) {
                        obj[field.server] = data[field.id].value;
                    } else {
                        obj[field.server] = '-1';
                    }
                    break;
                default:
                    obj[field.server] = data[field.id];
                    break;
            }

            return obj;
        }, {});
    };

    setReportsForm = (reportSignedFormModel, success, error) => {
        this.context.domainManager.setReportsForm(
            REPORTS.entity,
            reportSignedFormModel,
            (result) => {
                success(result);
            },
            error,
        );
    };

    setFollow = (reportId, follow, isShared = false, success, error) => {
        this.context.domainManager.setFollow(
            'reports',
            reportId,
            follow,
            isShared,
            () => {
                this.context.cacheManager.removeReportsDefault();
                success();
            },
            error,
        );
    };

    //method related with Breadcrumb
    completeTreeData = (treeFoldersNew) => {
        let tree = { ...treeFoldersNew };

        Object.keys(tree)?.forEach((key) => {
            tree[key]?.childNodes?.forEach((current) => {
                if (tree[current.id] && tree[current.id].folder && !tree[current.id].folder.node_id)
                    tree[current.id].folder.node_id = current.node_id;
            });
            if (tree[key] !== -1 && !tree[key].folder.node_id) {
                tree[key].folder.node_id = '-1';
            }
        });

        return tree;
    };

    //method related with Breadcrumb
    buildFolderSelectedArr = (folder, folderSelectedArr, treeFoldersNew, defaultTitle) => {
        const rootFolder = {
            name: defaultTitle,
            id: '-1',
            isShared: treeFoldersNew['-1'].isShared,
        };

        if (folder.id === '-1') return [rootFolder];

        let treeFolders = this.completeTreeData(treeFoldersNew);
        let newFolderSelectedArr = [];

        if (folderSelectedArr[0].id === folder.id) {
            const newArr = folderSelectedArr.filter((current) => current.id === folder.id);

            newFolderSelectedArr = newArr;
        } else {
            let shouldLoop = true;

            for (let i = 0; shouldLoop; i++) {
                if (newFolderSelectedArr.length === 0) {
                    newFolderSelectedArr = [folder];
                } else {
                    newFolderSelectedArr = [
                        treeFolders[newFolderSelectedArr[0].node_id].folder,
                        ...newFolderSelectedArr,
                    ];
                }
                shouldLoop = newFolderSelectedArr[0].node_id !== rootFolder.id;
            }
            newFolderSelectedArr = [rootFolder, ...newFolderSelectedArr];
        }

        return newFolderSelectedArr;
    };

    updateBreadcrumb = (data, folderSelectedArr, treeFoldersNew) => {
        const dispatch = Context.store.dispatch;
        const refreshFolderSelectedArr = Context.actions.ReportActions.refreshFolderSelectedArr;
        dispatch(refreshFolderSelectedArr(data, folderSelectedArr, treeFoldersNew));
    };

    shareItem = (report, action, isSigned) => {
        const dispatch = Context.store.dispatch;
        const openShareReport = Context.actions.ReportActions.openShareReport;
        dispatch(openShareReport(report, action, isSigned));
    };

    downloadItem = (report, action, isSigned) => {
        const dispatch = Context.store.dispatch;
        const openShareReport = Context.actions.ReportActions.openShareReport;
        dispatch(openShareReport(report, action, isSigned));
    };

    clickItem = (report, action) => {
        const dispatch = Context.store.dispatch;
        const openShareReport = Context.actions.ReportActions.openShareReport;
        dispatch(openShareReport(report, action));
    };
}
