import React, { memo, useRef, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Text, Button, useTheme } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';
import { formatCompleteDate } from 'utils/dates';
import { DashboardContext } from '../DashboardContext';

import DashboardCustomizePanel from './DashboardCustomizePanel';

const DashboardTopBar = memo(() => {
    const theme = useTheme();
    const { activeDashboard } = useContext(DashboardContext);
    const panelRef = useRef();
    const login = useSelector((state) => state.config.login);
    const userName = useSelector((state) => state.config?.userData?.nombre);

    const onRef = useCallback((ref) => {
        panelRef.current = ref;
    }, []);

    const openCustomizePanel = useCallback(() => panelRef.current.open(true), []);
    const closeCustomizePanel = useCallback(() => panelRef.current.open(false), []);

    const emojiColor = useMemo(() => {
        switch (activeDashboard?.config?.background?.color) {
            case theme.colors.orange200:
                return theme.colors.orange500;
            case theme.colors.blue200:
                return theme.colors.blue500;
            case theme.colors.aqua200:
                return theme.colors.aqua500;
            case theme.colors.green200:
                return theme.colors.green500;
            case theme.colors.yellow200:
                return theme.colors.yellow500;
            case theme.colors.red200:
                return theme.colors.red500;
            case theme.colors.purple200:
                return theme.colors.purple500;
            case theme.colors.neutral400:
                return theme.colors.neutral700;
            case theme.colors.neutral800:
                return theme.colors.neutral900;
            case theme.colors.neutralBase:
                return theme.colors.neutral700;
            default:
                return activeDashboard?.config?.background?.color || theme.colors.neutral700;
        }
    }, [
        activeDashboard?.config?.background?.color,
        theme.colors.aqua200,
        theme.colors.aqua500,
        theme.colors.blue200,
        theme.colors.blue500,
        theme.colors.green200,
        theme.colors.green500,
        theme.colors.neutral400,
        theme.colors.neutral700,
        theme.colors.neutral800,
        theme.colors.neutral900,
        theme.colors.neutralBase,
        theme.colors.orange200,
        theme.colors.orange500,
        theme.colors.purple200,
        theme.colors.purple500,
        theme.colors.red200,
        theme.colors.red500,
        theme.colors.yellow200,
        theme.colors.yellow500,
    ]);

    const todayDate = useMemo(() => {
        const date = formatCompleteDate(new Date());
        return date.charAt(0).toUpperCase() + date.slice(1);
    }, []);

    if (!login) return null;

    return (
        <div className="fm-dashboard__top-bar">
            <div className="fm-dashboard__header-hello">
                <Text type="subtitle">
                    {getLiteral('hello')}, {userName}
                </Text>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="none"
                    className="fm-dashboard__header-hello__icon"
                >
                    <path
                        fill={emojiColor}
                        fillRule="evenodd"
                        d="M8.668 11.862c-1.402 2.242-3.63 2.69-5.514 1.49-1.422-.906-1.947-2.108-1.796-3.761.046-1.053.388-2.298.708-3.399l.079-.281c.137-.496.286-1.035.412-1.264.168-.307.482-.4.771-.242.328.18.4.506.32 1.089l-.286 1.91c-.034.187 0 .283.069.331.087.052.18.04.284-.126L7.02 2.328c.198-.318.595-.422.888-.235.298.19.375.592.173.914L5.682 6.843c.147.028.302.07.46.133l2.772-4.432c.202-.322.594-.425.892-.234.298.185.375.591.173.908L7.239 7.6c.13.098.254.204.373.315L10.124 3.9c.202-.316.597-.42.893-.236.297.19.373.594.174.915L8.465 8.935c.096.16.173.327.243.486l1.899-3.034c.2-.322.593-.426.891-.236.293.187.37.594.173.91l-3.003 4.8Zm2.55-1.362c-.406.043-.557.898.057.833.613-.065 1.119-.361 1.518-.89a2.626 2.626 0 0 0 .527-1.821c-.047-.686-.776-.374-.745.078.032.453-.086.855-.352 1.208-.266.352-.601.55-1.006.592Zm.892 2.417c-.764.08-.576-.984-.072-1.037.504-.053.922-.3 1.253-.738.331-.44.477-.94.438-1.504-.039-.563.87-.95.928-.097.059.853-.16 1.61-.657 2.268-.497.658-1.127 1.027-1.89 1.108Z"
                        clipRule="evenodd"
                    />
                </svg>
                <Text type="body" color="neutral700">
                    • {todayDate}
                </Text>
            </div>
            <div className="fm-dashboard__top-bar__config">
                <Button icon="dashboardCustomize" onClick={openCustomizePanel}>
                    {getLiteral('action_customize')}
                </Button>
            </div>
            <DashboardCustomizePanel onRef={onRef} onClose={closeCustomizePanel} />
        </div>
    );
});

export default DashboardTopBar;
