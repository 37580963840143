import {
    ACTIVITIES_FIELDS,
    COMPANIES_FIELDS,
    OPPORTUNITIES_FIELDS,
    CONTACTS_FIELDS,
    AGENDA_FIELDS,
    PRODUCTS_FIELDS,
} from 'constants/Fields';
import {
    ACTIVITIES,
    COMPANIES,
    OPPORTUNITIES,
    CONTACTS,
    AGENDA,
    PRODUCTS,
} from 'constants/Entities';

const fieldsByEntity = {
    [ACTIVITIES.entity]: ACTIVITIES_FIELDS,
    [COMPANIES.entity]: COMPANIES_FIELDS,
    [OPPORTUNITIES.entity]: OPPORTUNITIES_FIELDS,
    [CONTACTS.entity]: CONTACTS_FIELDS,
    [AGENDA.entity]: AGENDA_FIELDS,
    [PRODUCTS.entity]: PRODUCTS_FIELDS,
};

export const getCrudFieldConfig = (entity, fieldId) => {
    if (!entity || !fieldsByEntity[entity.entity]) return {};
    const fields = fieldsByEntity[entity.entity];
    if (!fields[fieldId]) return {};
    const field = fields[fieldId];

    return {
        id: field.id,
        serverId: field.serverId,
        fieldConfiguration: field.b2Name,
        fieldValidation: field.b4Name,
    };
};

export const getCrudFieldId = (entity, fieldId) => {
    if (!entity || !fieldsByEntity[entity.entity]) return '';
    const fields = fieldsByEntity[entity.entity];
    if (!fields[fieldId]) return '';
    return fields[fieldId].id;
};
