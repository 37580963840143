import {
    ENTITY_MODAL_INIT,
    ENTITY_MODAL_INIT_SUCCESS,
    ENTITY_MODAL_CANCEL,
    ENTITY_CRUD_CLOSE,
    ENTITY_CRUD_HIDE,
} from 'constants/ActionTypes';
import { init as EntityCrudInit } from './EntityCrud';
import Context from 'managers/Context';
import { getActiveCrud } from 'utils/crud';

export function init({
    entity,
    labels,
    data,
    id,
    hideDelete,
    crudTab,
    extraInfo,
    forceRefresh,
    refreshIf,
    isCreateGlobal,
    isDetailCreateGlobal,
    hasMiddleButton,
    size,
    parentCrudInfo,
}) {
    return (dispatch, getState) => {
        const activeCrud = getActiveCrud(getState());

        dispatch({
            type: ENTITY_MODAL_INIT,
            entity: entity,
            id,
            labels,
            hideDelete,
            fromEntity: activeCrud,
            extraInfo,
            forceRefresh,
            refreshIf,
            isCreateGlobal,
            isDetailCreateGlobal,
            hasMiddleButton,
            size,
        });

        return EntityCrudInit({
            entity,
            id,
            extraId: null,
            isBulkAction: false,
            isFromDetail: false,
            data,
            isFromWorkFlow: false,
            isModal: true,
            crudTab,
            extraInfo,
            parentCrudInfo,
        })(dispatch, getState).then(() => {
            dispatch({
                type: ENTITY_MODAL_INIT_SUCCESS,
                entity: entity,
            });
        });
    };
}

export function cancel() {
    return (dispatch, getState) => {
        const state = getState();
        const entityModal = state.entityModal;
        const entityDetail = state?.entityDetail;
        const currentEntityModal = entityModal?.[entityModal?.active];
        const hasCrudInDetail = entityDetail?.[entityDetail?.active]?.hasCrudInDetail;
        const activeCrud = getActiveCrud(state);
        dispatch({
            type: ENTITY_MODAL_CANCEL,
        });
        if (hasCrudInDetail && !activeCrud.isModal) {
            dispatch({ type: ENTITY_CRUD_HIDE });
        } else {
            dispatch({ type: ENTITY_CRUD_CLOSE });
        }

        if (currentEntityModal?.fromEntity) {
            const entity = currentEntityModal?.entity?.entity || null;
            const manager = entity && Context.entityManager.getEntitiesManager(entity);
            if (manager?.afterCrudClose) manager.afterCrudClose();
            // dispatch({
            //     type: ENTITY_CRUD_PURGE_ACTIVE,
            //     fromEntity: currentEntityModal.fromEntity,
            // });
        }
    };
}
