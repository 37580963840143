import React from 'react';
import {
    ENTITY_CRUD_INIT,
    ENTITY_CRUD_INIT_SUCCESS,
    ENTITY_CRUD_INIT_ERROR,
    ENTITY_CRUD_HIDE,
    ENTITY_CRUD_CLOSE,
    ENTITY_CRUD_OPEN,
    ENTITY_CRUD_CHANGE,
    ENTITY_CRUD_SAVE,
    ENTITY_CRUD_SAVE_LOADING,
    ENTITY_CRUD_SAVE_ERROR,
    ENTITY_CRUD_CHANGE_HAS_FILES_TO_SAVE,
    ENTITY_CRUD_TOGGLE_CONFIRM_MODAL,
    ENTITY_CRUD_INIT_WORKFLOW,
    ENTITY_DETAIL_CLOSE,
    ENTITY_CRUD_CHECK_DUPLICATE,
    ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS,
    ENTITY_CRUD_CHECK_DUPLICATE_ERROR,
    ENTITY_CRUD_CHECK_DUPLICATE_RESET,
    ENTITY_CRUD_OVERLAY,
    ENTITY_CRUD_DUPLICATES_ERROR,
    ENTITY_CRUD_TOGGLE_EXTRAFIELD_MODAL,
    ENTITY_CRUD_UPDATE_TABLE_ERRORS,
    ENTITY_CRUD_SET_ACTIVE,
} from 'constants/ActionTypes';
import { ACTIVITIES, COMPANIES, CONTACTS, OPPORTUNITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getFieldWorkFlow } from 'utils/fm';
import { getEntityFromString } from 'utils/getEntityFromString';
import { ensureRoute, backRoute, backRouteWorkflow, getCurrentPath } from 'utils/routes';
import { cleanUrl } from 'utils/url';
import CheckDuplicateContent from 'containers/components/EntityCrud/components/CheckDuplicateContent';
import { CompanyAvatar, ContactAvatar } from 'containers/components/avatars';
import { trackEvent } from 'utils/tracking';
import { getActiveCrudName, getActiveCrud, getActiveLateralCrudName } from 'utils/crud';
import { getCrudFieldId } from 'utils/fm/fields';
import { getCrudVersion } from 'utils/flags';
import { EVENT_TYPES } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';
import { duplicatesToast, clearToast } from 'utils/toast';

const ENUM_IS_VISIBLE = {
    neutral: -1,
    isHidden: 0,
    isVisible: 1,
};

// this function is used to calculate which fields are going to be hidden or not
// because of the need to apply dynamism between extrafields, we need to make this
// function recursive to calculate the visibility of the fields affected by the
// visibility we are calculating originally. Because of that, we need 3 states of
// isVisible parameter, neutral to calculate the first level, and the other 2
// to propagate the visibility of this field to the other fields that are dependant
// on that field.
function calculateDynamicHiddenMap(
    dynamicHiddenMap,
    dynamicMap,
    key,
    value,
    data,
    isVisible = ENUM_IS_VISIBLE.neutral,
) {
    if (typeof value !== 'string' && value?.value) value = value.value; // if the original field is a fuzzy, we need to access to value.value
    return Object.keys(dynamicMap[key]).reduce((obj, dynamicField) => {
        if (!obj[dynamicField]) obj[dynamicField] = {};
        if (isVisible === ENUM_IS_VISIBLE.neutral || isVisible === ENUM_IS_VISIBLE.isVisible) {
            const isParentVisible = obj[key] !== true;
            // when multi value objects
            if (value && Array.isArray(value) && value?.length && value[0]?.value)
                value = value.map((v) => v?.value || v);

            obj[dynamicField] = !(
                dynamicMap[key][dynamicField] &&
                value !== undefined &&
                value !== '' &&
                value !== null &&
                isParentVisible &&
                (dynamicMap[key][dynamicField].includes(value.toString()) ||
                    dynamicMap[key][dynamicField].includes(value) ||
                    (Array.isArray(value) &&
                        dynamicMap[key][dynamicField]?.some((el) => value?.includes(el))) ||
                    dynamicMap[key][dynamicField] === '_all_')
            );
        } else {
            obj[dynamicField] = true;
        }
        if (dynamicField && dynamicMap[dynamicField]) {
            const isVisible = obj[dynamicField]
                ? ENUM_IS_VISIBLE.isHidden
                : ENUM_IS_VISIBLE.isVisible;
            calculateDynamicHiddenMap(
                obj,
                dynamicMap,
                dynamicField,
                data[dynamicField],
                data,
                isVisible,
            );
        }
        return obj;
    }, dynamicHiddenMap);
}

export function initWorkFlow() {
    return (dispatch, getState) => {
        let workFlowPersist = sessionStorage.getItem('fm_workflow_crud_persist');
        if (workFlowPersist) {
            try {
                const workFlowFieldName = sessionStorage.getItem('fm_workflow_action_fieldName');
                workFlowPersist = JSON.parse(workFlowPersist);
                let { data, entity, hasCrudInDetail, openDetail, fieldsToUpdate } = workFlowPersist;
                dispatch(
                    init({
                        entity: entity,
                        id: data.id,
                        extraId: null,
                        isBulkAction: false,
                        isFromDetail: false,
                        data: null,
                        isFromWorkFlow: true,
                    }),
                ).then(() => {
                    let workFlowEnd = sessionStorage.getItem('fm_workflow_action_end');
                    const toSave = workFlowEnd === 'true';
                    const active = getActiveCrudName(getState());

                    if (workFlowFieldName && !toSave) {
                        data[workFlowFieldName] =
                            state[active].originalData[workFlowFieldName] || '';
                    }

                    dispatch({
                        type: ENTITY_CRUD_INIT_WORKFLOW,
                        data,
                        loading: toSave,
                        loadingSave: toSave,
                    });
                    if (toSave) {
                        dispatch(
                            save({
                                entity,
                                openDetail,
                                hasCrudInDetail,
                                fieldsToUpdate,
                                fromWorkFlow: true,
                            }),
                        );
                    } else {
                        // remove workflow word if we don't need to do anything more
                        ensureRoute(backRouteWorkflow());
                    }
                    sessionStorage.removeItem('fm_workflow_action_end');
                });
            } catch (ex) {
                console.error('something went wrong trying to restore previous crud and saving it');
            }
        }
    };
}

const checkFirstField = (schema) => {
    let isFirstTab = true;
    let fieldName = null;
    schema
        .filter((tab) =>
            tab.fields?.find(
                (field) =>
                    (!field.hidden && !field.inputs) ||
                    field.inputs?.find((input) => !input.hidden),
            ),
        )
        .map((tab, index) => {
            let finalTab = { ...tab };
            if (tab.beforeRenderCrudSection) {
                finalTab = tab.beforeRenderCrudSection(tab);
            }

            finalTab.fields?.every((field) => {
                if (isFirstTab && !field.readOnly && !field.hidden) {
                    fieldName = field.id;
                    isFirstTab = false;
                    return false;
                }
                return true;
            });
        });
    return fieldName;
};

export function init({
    entity,
    id,
    extraId,
    isBulkAction = false,
    isFromDetail = false,
    data,
    isFromWorkFlow = false,
    isModal = false,
    crudTab,
    extraInfo,
    parentCrudInfo,
    isFromTab = false,
    avoidRedirects = false,
}) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const state = getState();
            const name = entity.entity;
            const manager = Context.entityManager.getEntitiesManager(name);
            const entityCrud = state.entityCrud;
            const active = getActiveCrudName(state);

            if (
                active &&
                entityCrud[active] &&
                entityCrud[active].id === id &&
                id &&
                !isFromDetail &&
                !isModal
            ) {
                if (
                    !(
                        manager &&
                        manager.isEntityReadOnly &&
                        manager.isEntityReadOnly(entityCrud[active].data)
                    )
                ) {
                    dispatch({ type: ENTITY_CRUD_OPEN });
                }
                return;
            }

            const crudVersion = getCrudVersion(entity);

            dispatch({
                type: ENTITY_CRUD_INIT,
                entity: name,
                id,
                extraId,
                isBulkAction,
                isFromDetail,
                isModal,
                crudTab,
                extraInfo,
                parentCrudInfo,
                version: crudVersion,
                isFromTab,
                avoidRedirects,
            });

            if (!isFromWorkFlow) {
                // remove this key in this case to prevent getting wrong data
                // except is only from a current workFlow
                sessionStorage.removeItem('fm_workflow_crud_persist');
                sessionStorage.removeItem('fm_workflow_action');
                sessionStorage.removeItem('fm_workflow_action_id');
                sessionStorage.removeItem('fm_workflow_action_fieldName');
            }

            Context.entityCrudManager
                .init({
                    entity,
                    id,
                    isBulkAction,
                    isFromDetail,
                    data,
                    isModal,
                    crudTab,
                    extraInfo,
                    version: crudVersion,
                })
                .then(
                    ({
                        schema,
                        mappedSchema,
                        configMap,
                        dependencyMap,
                        dynamicMap,
                        data,
                        preComponents,
                    }) => {
                        if (id && data && !isFromDetail) {
                            if (
                                manager &&
                                manager.isEntityReadOnly &&
                                manager.isEntityReadOnly(data)
                            ) {
                                dispatch({
                                    type: ENTITY_CRUD_CLOSE,
                                    tabs: getState().entityDetail.tabs,
                                });
                                return;
                            }
                        }

                        if (
                            manager &&
                            manager.checkIfCanOpenCrud &&
                            !manager.checkIfCanOpenCrud(data)
                        ) {
                            dispatch(close());
                            return;
                        }

                        // Set isReadOnly if no permissions
                        const crudPermission =
                            state.config?.permission?.crud_permission?.[entity.permission];
                        if (id && crudPermission && !crudPermission.update) data.isReadOnly = true;

                        let dynamicHiddenMap = {};
                        // 1. Set dynamicHiddenMap with all fields in dynamicMap setted to true, so all dynamic fields will be hidden initially.
                        Object.keys(dynamicMap).forEach((key) => {
                            Object.keys(dynamicMap[key]).forEach((key2) => {
                                dynamicHiddenMap[key2] = true;
                            });
                        });

                        // 2. As all the fields are setted hidden = true, now check the default values and overwrite the fields in dynamicHiddenMap that need to be visible.
                        Object.keys(data).forEach((key) => {
                            if (!mappedSchema || !mappedSchema[key]) return;
                            const mapKey = mappedSchema[key].fieldConfiguration;
                            if (mapKey && dynamicMap[mapKey]) {
                                dynamicHiddenMap = calculateDynamicHiddenMap(
                                    dynamicHiddenMap,
                                    dynamicMap,
                                    mapKey,
                                    data[key],
                                    data,
                                );
                            }
                        });

                        let promises = [];

                        if (manager && manager.whileInitCrud) {
                            promises.push(manager.whileInitCrud(data, mappedSchema));
                        }
                        const firstFieldToFocus = checkFirstField(schema);
                        Promise.all(promises).then(([newData]) => {
                            if (newData) data = newData;
                            dispatch({
                                type: ENTITY_CRUD_INIT_SUCCESS,
                                schema,
                                mappedSchema,
                                configMap,
                                dependencyMap,
                                dynamicMap,
                                dynamicHiddenMap,
                                data,
                                firstFieldToFocus,
                                preComponents,
                            });

                            if (manager && manager.changeFieldsFromWeb3) {
                                const fieldsToChange = manager.changeFieldsFromWeb3();
                                if (fieldsToChange) {
                                    dispatch(changeFields(fieldsToChange));
                                    cleanUrl();
                                }
                            }

                            resolve();
                        });
                    },
                )
                .catch((error) => {
                    console.error(error);
                    dispatch({
                        type: ENTITY_CRUD_INIT_ERROR,
                        error,
                    });
                    dispatch(close());
                });
        });
}

// fields -> new changed fields
export function changeFields(fields) {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const state = getState();
            const entityCrud = state.entityCrud;
            const active = getActiveCrudName(state);
            if (!active || !entityCrud?.[active]) return;
            const manager = Context.entityManager.getEntitiesManager(entityCrud[active].entity);
            const { data, dependencyMap, dynamicMap, mappedSchema, originalData, configMap } =
                entityCrud[active];

            fields = Object.keys(fields).reduce((obj, key) => {
                if (!mappedSchema || !mappedSchema[key]) return obj;
                const mapKey = mappedSchema[key].fieldConfiguration;

                if (mapKey && dependencyMap[mapKey.toLowerCase()]) {
                    dependencyMap[mapKey.toLowerCase()].forEach((son) => {
                        if (!mappedSchema?.[son]?.inputAttrs?.parentFieldNoRemove) {
                            if (mappedSchema?.[son]?.type === 'fuzzySearchMultiple') {
                                fields[son] = [];
                            } else fields[son] = mappedSchema?.[son]?.defaultValue || '';
                        }
                    });
                }
                return obj;
            }, fields);

            // check if we have some workFlows
            const entityConstant = getEntityFromString(entityCrud[active].entity);
            const workFlows =
                entityConstant &&
                entityConstant.workFlow &&
                getState().config.workFlows &&
                getState().config.workFlows[entityConstant.workFlow];
            let workFlowAction = entityCrud[active].workFlowAction;
            if (workFlows) {
                let idUserType = getState().config.userData.idTipoUsuario;
                Object.keys(fields).map((keyField) => {
                    if (fields[keyField]) {
                        let field = mappedSchema?.[keyField];
                        if (field) {
                            const fieldWorkFlow = getFieldWorkFlow(
                                field.fieldConfiguration,
                                workFlows,
                                idUserType,
                            );

                            let oldFieldValue = entityCrud[active].data[keyField] || '-1';
                            if (oldFieldValue !== '-1' && oldFieldValue.value)
                                oldFieldValue = oldFieldValue.value;

                            const newFieldValue = fields[keyField].value
                                ? fields[keyField].value
                                : fields[keyField];

                            if (fieldWorkFlow && fieldWorkFlow[oldFieldValue]) {
                                if (oldFieldValue === '-1') {
                                    // we don't accept actions from value -1
                                    workFlowAction = null;
                                } else if (originalData[keyField] === newFieldValue) {
                                    // the user move to the original value of the field,
                                    // so we cannot dispatch any action
                                    workFlowAction = null;
                                } else {
                                    let workFlowField = fieldWorkFlow[oldFieldValue].relations.find(
                                        (field) => field.value === parseInt(newFieldValue, 10),
                                    );
                                    if (
                                        workFlowField &&
                                        workFlowField.actions &&
                                        workFlowField.actions[0]
                                    ) {
                                        // at this moment, we only accept 1 action
                                        workFlowAction = workFlowField;
                                        // add the name of the field because maybe we need it
                                        // i.e. if user cancel action and we need to recover
                                        // previous value
                                        workFlowAction.fieldName = keyField;
                                    }
                                }
                            }
                        }
                    }
                });
            }

            if (manager && manager.changeFields) fields = manager.changeFields(fields);

            // Calculating all dynamisms
            const dynamicData = {
                ...data,
                ...fields,
            };

            const dynamicHiddenMap = Object.keys(dynamicMap).reduce((dynamicObj, dynamicKey) => {
                const configKey = configMap[dynamicKey];
                return calculateDynamicHiddenMap(
                    dynamicObj,
                    dynamicMap,
                    dynamicKey,
                    dynamicData[configKey],
                    data,
                );
            }, {});

            function dispatchChanges(newFields) {
                dispatch({
                    type: ENTITY_CRUD_CHANGE,
                    fields: newFields,
                    dynamicHiddenMap,
                    workFlowAction,
                });
            }

            dispatchChanges(fields);
            resolve(fields);

            if (manager && manager.postChangeFields)
                manager.postChangeFields({ fields, data, dispatchChanges, state });
        });
    };
}

export function changeHasFilesToSave(bool) {
    return (dispatch, getState) => {
        dispatch({ type: ENTITY_CRUD_CHANGE_HAS_FILES_TO_SAVE, hasFilesToSave: bool || false });
    };
}

const changeFieldsAfterCreateInContext = (entityCrud, data, entity) => {
    const parentCrudInfo = entityCrud.parentCrudInfo || null;
    if (
        !data?.Insert?.id ||
        !parentCrudInfo?.id ||
        !parentCrudInfo?.fieldToChange ||
        !parentCrudInfo?.list
    )
        return;

    const fieldNameByEntity = {
        [COMPANIES.entity]: ['name'],
        [CONTACTS.entity]: ['Name', 'Surnames'],
        [OPPORTUNITIES.entity]: ['reference'],
    };

    const searchFieldName = fieldNameByEntity[entity.entity] || null;

    if (!searchFieldName) return;

    let text = '';
    if (searchFieldName) {
        text = searchFieldName.reduce((str, current, index) => {
            let newText = '';
            const searchFieldId = (current && getCrudFieldId(entity, current)) || '';
            newText = entityCrud.data[searchFieldId];
            if (newText) {
                if (index === 0) {
                    if (newText) str = newText;
                } else {
                    newText = entityCrud.data[searchFieldId];
                    if (newText) str = `${str} ${newText}`;
                }
            }
            return str;
        }, '');
    }

    if (!text || !data.Insert.id) return;
    const state = Context.store.getState();

    const lateralEntityCrudName = getActiveLateralCrudName(state);
    const lateralEntityCrud = state.entityCrud?.[lateralEntityCrudName] || null;
    const lateralData = lateralEntityCrud?.data || null;
    const oldValues = lateralData?.[parentCrudInfo.fieldToChange] || [];

    let values = null;

    if (parentCrudInfo.type === 'fuzzySearchMultiple') {
        values = [...oldValues, { label: text, value: data.Insert.id }];
    } else {
        values = { label: text, value: data.Insert.id };
    }

    const fields = {
        [parentCrudInfo.fieldToChange]: values,
    };

    // To give time to all the processes in front end ocurr correctly
    // Has no backend dependency
    setTimeout(() => {
        Context.store.dispatch(changeFields(fields));
    }, 500);
};

export function save({
    entity,
    openDetail = true,
    hasCrudInDetail = false,
    fieldsToUpdate = {},
    fromWorkFlow = false,
    forceRefresh = false,
    info = {},
}) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            dispatch({ type: ENTITY_CRUD_SAVE_LOADING, state: true });
            const entityCrud = getActiveCrud(getState());
            const manager = Context.entityManager.getEntitiesManager(entityCrud);
            let persistCrudWorkFlow = {
                entity,
                openDetail,
                hasCrudInDetail,
                fieldsToUpdate,
                data: entityCrud.data,
            };

            let entityDetail = getState().entityDetail;
            let saveFromDetail =
                entityDetail?.[entityDetail.active]?.hasCrudInDetail &&
                hasCrudInDetail &&
                entityDetail.isOpen;

            const entityList = getState()?.entityList?.[entity.entity] || null;
            const useLazyLoad = entityList?.useLazyLoad || false;

            const isFromTab =
                entityCrud.isFromTab || Object.entries(getState().entityDetail.tabs).length > 1;

            Context.entityCrudManager
                .save({
                    entity,
                    entityState: entityCrud,
                    persistCrudWorkFlow,
                    fromDetail: !getState().entityCrud.isOpen,
                    fromWorkFlow,
                    customState: null,
                    hasFilesToSave: entityCrud.hasFilesToSave || false,
                })
                .then((data) => {
                    // if duplicated then we must stop and show an alert
                    if (data?.Status?.code === 'DuplicatedEntity') return resolve(data);

                    if (entityCrud.data.id) {
                        trackEvent({
                            entity,
                            entityId: entityCrud.data.id,
                            idEventType: EVENT_TYPES.update,
                        });
                    } else if (data?.Insert?.id) {
                        trackEvent({
                            entity,
                            entityId: data.Insert.id,
                            idEventType: EVENT_TYPES.create,
                        });
                    }

                    if (!saveFromDetail && !entityCrud.isModal) {
                        dispatch({ type: ENTITY_CRUD_SAVE });
                        dispatch({ type: ENTITY_CRUD_CLOSE, tabs: getState().entityDetail.tabs });
                    } else if (!entityCrud.isModal) {
                        const updatedFields = Object.keys(fieldsToUpdate).reduce((obj, key) => {
                            if (entityCrud.data[key]) {
                                obj[key] = entityCrud.data[key];
                            }
                            return obj;
                        }, {});

                        dispatch(
                            Context.actions.EntityActions.updateEntity(
                                entity,
                                entityCrud.data.id,
                                updatedFields,
                            ),
                        );
                        dispatch({ type: ENTITY_CRUD_HIDE });
                    }

                    // crud response could have two options... a normal one, returning the data
                    // and another one, backend style, returning an XML parsed as a JSON by proxy
                    let id;

                    // Hook for generic crud
                    if (
                        manager &&
                        manager.shouldOpenDetail &&
                        manager.shouldOpenDetail !== undefined &&
                        (!entityCrud.isModal ||
                            (entityCrud.isModal &&
                                Object.keys(entityDetail.tabs).includes(
                                    `${entity.entity}-${data.id || entityCrud.id}`,
                                )))
                    )
                        openDetail = manager.shouldOpenDetail(data);

                    if (openDetail) {
                        if (data && data.id) {
                            // TODO: Why do we have it repeated? Refresh calls open :S
                            // TODO: seems that refresh only get the new info for detail
                            // TODO: if the detail is opened before, so we can call
                            // TODO: just open and the detail will appear
                            // dispatch(Context.actions.EntityDetailActions.refresh(entity, data.id));
                            id = data.id;
                        } else if (entityCrud.id) {
                            id = entityCrud.id;
                        } else if (data && Array.isArray(data.Insert) && data.Insert.length > 0) {
                            id = data.Insert[0].id;
                        } else if (
                            data &&
                            data.Insert &&
                            data.Insert.num &&
                            data.Insert.num === '1' &&
                            data.Insert.id
                        ) {
                            id = data.Insert.id;
                        } else if (
                            data &&
                            data.Updated &&
                            data.Updated.num &&
                            data.Updated.num === '1'
                        ) {
                            id = entityCrud.id;
                        }

                        if (id) {
                            // TODO null parameter is extra in open method. In a future
                            // TODO maybe we need to fill it with some data

                            const shouldDoHiddenRefresh =
                                getState().entityDetail.isOpen &&
                                entityCrud.entity !== ACTIVITIES.entity;

                            if (!shouldDoHiddenRefresh) {
                                dispatch(
                                    Context.actions.EntityDetailActions.open(
                                        entity,
                                        id,
                                        hasCrudInDetail,
                                        null,
                                        true,
                                        false,
                                        null,
                                        isFromTab,
                                        entityCrud.avoidRedirects,
                                    ),
                                );

                                if (!isFromTab && !entityCrud.avoidRedirects) {
                                    const activityType = entityCrud?.extraInfo?.activityType;
                                    const isMapOpen = window.location.href.includes('/map/');
                                    const route = activityType
                                        ? `${entity.route}/${id}/${activityType}`
                                        : isMapOpen
                                          ? `${entity.route}/map/${id}`
                                          : `${entity.route}/${id}`;
                                    ensureRoute(route);
                                }
                            } else {
                                dispatch(
                                    Context.actions.EntityDetailActions.hiddenRefresh(entity, id),
                                );
                            }
                        }
                    } else if (!entityCrud.isModal && !entityCrud.avoidRedirects) {
                        if (manager && manager.backRoute) {
                            if (data?.Insert?.id) manager.backRoute(entityCrud, data?.Insert?.id);
                            else manager.backRoute(entityCrud);
                        } else ensureRoute(backRoute());
                    }

                    const activeList = getState().entityList.active || '';
                    const activeEntity = getEntityFromString(activeList);
                    // Prevents to make an unnecessary call to refresh the list when we
                    // create an entity from global create and we are not in the same menu section
                    // of the entity that we are creating
                    if (info.refreshIf?.length && activeEntity) {
                        if (!info.refreshIf.includes(activeEntity)) {
                            resolve(data);
                        }
                    }

                    // useLazyLoad for w5 table
                    // make 2 possible calls because null removes default prop values
                    if (manager?.customInitList) {
                        manager.customInitList(entityCrud.data, data?.Insert?.id);
                    } else if (useLazyLoad) {
                        dispatch(
                            Context.actions.EntityListActions.init(
                                entity,
                                false,
                                null,
                                null,
                                null,
                                useLazyLoad,
                            ),
                        );
                    } else if (
                        (!entityCrud.isModal || (entityCrud.isModal && forceRefresh)) &&
                        (!isFromTab || (isFromTab && entityCrud.entity === activeList))
                    ) {
                        let pageSize = null;
                        if (manager && manager.getCustomPageSize) {
                            pageSize = manager.getCustomPageSize();
                        }

                        dispatch(
                            Context.actions.EntityListActions.init(entity, true, pageSize || null),
                        );
                    }

                    changeFieldsAfterCreateInContext(entityCrud, data, entity);

                    resolve(data);
                })
                .catch((e) => {
                    console.error(e);
                    let serverError = false;
                    if (hasCrudInDetail) {
                        const tabs = getState().entityDetail.tabs;
                        dispatch({ type: ENTITY_DETAIL_CLOSE, tabs });
                        const wasCrudOpened = getState().entityCrud.isOpen || false;
                        dispatch(open());
                        if (
                            !wasCrudOpened &&
                            Object.entries(tabs).length === 1 &&
                            !entityCrud.avoidRedirects
                        ) {
                            const currentRoute = getCurrentPath();
                            ensureRoute(`${currentRoute}/edit`);
                        }
                    } else if (!e || (e && e.serverError)) {
                        serverError = true;
                    }

                    dispatch({
                        type: ENTITY_CRUD_SAVE_ERROR,
                        errors: e ? e.errors : e,
                        firstErrorField: e ? e.firstErrorField : e,
                    });
                })
                .finally(() => {
                    if (!saveFromDetail) dispatch({ type: ENTITY_CRUD_SAVE_LOADING, state: false });
                });
        });
}

export function forceUpdateErrors(entity, error) {
    return (dispatch, getState) => {
        dispatch({
            type: ENTITY_CRUD_SAVE_ERROR,
            errors: error.errors,
            firstErrorField: error.firstErrorField,
        });
    };
}

export function updateTableErrors(errors) {
    return (dispatch, getState) => {
        dispatch({
            type: ENTITY_CRUD_UPDATE_TABLE_ERRORS,
            errors: errors,
        });
    };
}

export function open() {
    return (dispatch) => {
        dispatch({ type: ENTITY_CRUD_OPEN });
    };
}

export function close(hasCrudInDetail = false) {
    return (dispatch, getState) => {
        const state = getState();
        const entityCrudActiveName = getActiveCrudName(state);
        const entityDetail = getState().entityDetail[entityCrudActiveName];

        const entityCrudActive = getActiveCrud(state);

        const manager =
            entityCrudActive && Context.entityManager.getEntitiesManager(entityCrudActive);

        const isModal = entityCrudActive.isModal || false;
        if (hasCrudInDetail && !isModal) {
            dispatch({ type: ENTITY_CRUD_HIDE });
        } else {
            dispatch({ type: ENTITY_CRUD_CLOSE, tabs: getState().entityDetail.tabs });
        }

        if (entityDetail || entityCrudActive.isFromTab) {
            // open to prevent the router default action
            dispatch(Context.actions.EntityDetailActions.openHidden());
        }

        if (manager?.afterCrudClose) manager.afterCrudClose();

        dispatch({ type: ENTITY_CRUD_CHECK_DUPLICATE_RESET });

        if (entityCrudActive.avoidRedirects) return;
        if (manager && manager.backRoute) {
            manager.backRoute(entityCrudActive);
        } else {
            ensureRoute(backRoute());
        }
    };
}

export function toggleConfirmModal(bool) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_CRUD_TOGGLE_CONFIRM_MODAL,
            bool,
        });
    };
}

export function toggleExtraFieldsModal(bool, url, queryString) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_CRUD_TOGGLE_EXTRAFIELD_MODAL,
            bool,
            url,
            queryString,
        });
    };
}

export function deleteEntity(entity) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const entityCrud = getActiveCrud(getState());
            const isModal = entityCrud.isModal || false;
            const entityList = getState()?.entityList?.[getState()?.entityList?.active] || null;
            const useLazyLoad = entityList?.useLazyLoad || false;
            const manager =
                (entityCrud && Context.entityManager.getEntitiesManager(entityCrud)) || null;
            Context.entityCrudManager
                .delete(entity, entityCrud)
                .then((data) => {
                    if (isModal) return resolve(data);
                    dispatch({ type: ENTITY_DETAIL_CLOSE });
                    dispatch({ type: ENTITY_CRUD_CLOSE, tabs: getState().entityDetail.tabs });
                    dispatch(Context.actions.EntityListSelectActions.resetListSelect(entity));
                    if (manager && manager.afterCrudClose) manager.afterCrudClose();
                    const id = entityCrud.data?.id || '';
                    if (id) trackEvent({ entity, entityId: id, idEventType: EVENT_TYPES.delete });

                    // useLazyLoad for w5 table
                    // make 2 possible calls because null removes default prop values
                    if (manager?.customInitList) {
                        manager.customInitList(entityCrud.data, data?.Insert?.id);
                    } else if (useLazyLoad) {
                        dispatch(
                            Context.actions.EntityListActions.init(
                                entity,
                                false,
                                null,
                                null,
                                null,
                                useLazyLoad,
                            ),
                        );
                    } else {
                        let pageSize = null;
                        if (manager && manager.getCustomPageSize) {
                            pageSize = manager.getCustomPageSize();
                        }
                        dispatch(
                            Context.actions.EntityListActions.init(entity, true, pageSize || null),
                        );
                    }

                    resolve(data);
                    const entityConstant = getEntityFromString(entityCrud.entity);
                    if (!entityConstant.withoutRedirect && !entityCrud.avoidRedirects) {
                        const isMapOpen = window.location.href.includes('/map/');
                        ensureRoute(
                            isMapOpen ? `${entityConstant.route}/map` : entityConstant.route,
                        );
                    }
                })
                .catch((e) => {
                    console.error(e);
                    let serverError = !e || (e && e.serverError);

                    reject({ errors: e.errors, serverError });
                });
        });
}

export function checkDuplicate(entity, field, fieldLabel, value) {
    return (dispatch, getState) => {
        const state = getState();
        const active = getActiveCrudName(state);
        const crud = active && state.entityCrud[active];
        const isEdit = crud && crud.id;
        let toastsDuplicate = (crud && crud.duplicates && crud.duplicates.toasts) || {};

        if (isEdit || state?.config?.permission?.checkDuplicateFields !== '1') return;
        if (toastsDuplicate && toastsDuplicate[field] && toastsDuplicate[field].value === value) {
            return;
        }

        dispatch({ type: ENTITY_CRUD_CHECK_DUPLICATE, currentField: field });

        if (value) {
            Context.domainManager
                .getDuplicateForFields(entity, field, value)
                .then((data) => {
                    if (
                        toastsDuplicate &&
                        toastsDuplicate[field] &&
                        toastsDuplicate[field].value !== value
                    ) {
                        clearToast(toastsDuplicate[field].id);
                    }

                    const translateEntities = {
                        accounts: 'companies',
                        contacts: 'contacts',
                    };

                    let avatar;
                    const newEntity = translateEntities[data.entity];

                    switch (newEntity) {
                        case 'contacts':
                            let initials = '';
                            if (data.name) {
                                const nameSplitted = data.name.toUpperCase().split(' ');
                                initials = `${nameSplitted[0][0]}${nameSplitted[1][0]}`;
                            }
                            avatar = (
                                <ContactAvatar size="large" id={data.id} initials={initials} />
                            );
                            break;
                        case 'companies':
                        default:
                            // we are using CompanyAvatar component which is using the old format of backend to
                            // get the image of the company. In some cases, backend is not returning the image
                            // of the company and you need to obtain an image with the idLogo property
                            // of the company
                            avatar = <CompanyAvatar size="large" id={data.id} />;
                            break;
                    }

                    const toastId = duplicatesToast({
                        title: `${getLiteral('label_duplicate_detected')} - ${fieldLabel}`,
                        icon: avatar,
                        content: (
                            <CheckDuplicateContent
                                entity={data.entity}
                                id={data.id}
                                name={data.name}
                                visible={data.visible}
                                fieldLabel={fieldLabel}
                                toastField={field}
                            />
                        ),
                        onClose: () =>
                            Context.store.dispatch(
                                Context.actions.EntityCrudActions.closeDuplicateToastFromButton(
                                    field,
                                ),
                            ),
                        className: 'check-duplicate-toast',
                    });

                    dispatch({
                        type: ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS,
                        toastsDuplicate,
                        fieldToRemove: field,
                        newField: field,
                        newValues: {
                            id: toastId,
                            value: value,
                        },
                    });
                })
                .catch((error) => {
                    dispatch({ type: ENTITY_CRUD_CHECK_DUPLICATE_ERROR });
                });
        } else {
            if (toastsDuplicate[field]) {
                clearToast(toastsDuplicate[field].id);
                dispatch({
                    type: ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS,
                    toastsDuplicate,
                    fieldToRemove: field,
                });
            } else {
                dispatch({ type: ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS });
            }
        }
    };
}

export function closeDuplicateToastFromButton(toastField) {
    return (dispatch, getState) => {
        const activeCrud = getActiveCrud(getState());
        let toastsDuplicate =
            (activeCrud && activeCrud?.duplicates && activeCrud?.duplicates?.toasts) || {};

        dispatch({
            type: ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS,
            toastsDuplicate,
            fieldToRemove: toastField,
        });
    };
}

export function toggleDuplicatesOverlay(bool) {
    return (dispatch, getState) => {
        const activeCrud = getActiveCrud(getState());
        const entity = activeCrud?.entity;
        dispatch({
            type: ENTITY_CRUD_OVERLAY,
            entity,
            bool,
        });
    };
}

export function setDuplicatesError() {
    return (dispatch, getState) => {
        const activeCrud = getActiveCrud(getState());
        const entity = activeCrud?.entity;
        dispatch({
            type: ENTITY_CRUD_DUPLICATES_ERROR,
            entity,
        });
    };
}

export function setActiveCrud(active) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_CRUD_SET_ACTIVE,
            active,
        });
    };
}
