import React, { memo } from 'react';
import { Text, Spacer } from 'hoi-poi-ui';
import './styles.scss';

const SettingsLayout = memo(({ title, description, children }) => {
    return (
        <div className="fm-settings-layout">
            <div className="fm-settings-layout__header">
                <Text type="h6" className="fm-settings-layout__title">
                    {title}
                </Text>
                <Spacer y={4} />
                {description && (
                    <Text className="fm-settings-layout__description">{description}</Text>
                )}
            </div>
            <Spacer y={6} />
            <div className="fm-settings-layout__content">{children}</div>
        </div>
    );
});

export default SettingsLayout;
