import { axios } from './axios';
import { handleErrors } from './utils';

export function getGroups(type) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/group/user/v1`, {
                params: {
                    type,
                },
            })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getEntities(type) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/group/user/v1/entities`, {
                params: {
                    type,
                },
            })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createGroup(body) {
    return new Promise((resolve, reject) => {
        axios
            .post(`/group/user/v1`, body)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
