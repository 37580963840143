import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServerListActions from 'actions/ServerList';

import { Select as SelectHoi } from 'hoi-poi-ui';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    loadOptions: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

const Select = ({
    className,
    label,
    list,
    filterOptions,
    hidden,
    onChange,
    value,
    options = [],
    disabled,
    readOnly,
    shouldRenderField,
    isClearable = true,
    description,
    hint,
    mandatory,
    error,
    isMulti,
    getList,
    ...props
}) => {
    const classes = classnames('fm-field-server-list', className);
    const loadOptions = useCallback(() => {
        if (filterOptions) return getList(list).then(filterOptions);
        return getList(list);
    }, [filterOptions, getList, list]);

    if (hidden) return null;
    if (shouldRenderField && !shouldRenderField(options, list)) return null;

    return (
        <div className={classes}>
            <SelectHoi
                label={label}
                onChange={onChange}
                value={value}
                isReadOnly={readOnly || disabled}
                options={options}
                loadOptions={list ? loadOptions : undefined}
                error={error}
                placeholder={hint}
                hint={description}
                isRequired={mandatory}
                isClearable={isClearable}
                isMulti={isMulti}
                isFullWidth
                showMediaInSelectedValues
                {...props}
            />
        </div>
    );
};

Select.propTypes = propTypes;

export default memo(connect(null, mapDispatchToProps)(Select));
