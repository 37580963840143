import sha1 from 'sha1';
import Context from 'managers/Context';
import {
    OPPORTUNITIES,
    COMPANIES,
    CONTACTS,
    TASKS,
    AGENDA,
    ACTIVITIES,
    SALESORDERS,
} from 'constants/Entities';
import { getEnvironmentRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { formatExtra } from 'domain/FilterApiWrapper';

export const getFieldWorkFlow = (idField, workFlow, idUserType) => {
    if (!idField) return;
    if (!workFlow || !workFlow[idField]) return;

    // TODO for the moment we only support idtipousuario
    if (workFlow[idField] && workFlow[idField]['idTipoUsuario']) {
        return workFlow[idField]['idTipoUsuario'][idUserType];
    }
    // TODO check if we have value for any other workflow field
    // TODO maybe we need to change the schema of the crud to match
    // TODO id with fieldConfiguration, because the workflow works with
    // TODO "fieldConfiguration" and the values in data with id
};

export const dependantOptionsFilter = (options, dependantValue) => {
    let dependantValueString = '';
    if (!isNaN(dependantValue)) dependantValueString = dependantValue.toString();
    return options.filter(
        (option) =>
            option.idparent === dependantValue ||
            option.idparent === dependantValueString ||
            option.idparent === '-1' ||
            option.idparent === '' ||
            option.idparent === '0',
    );
};

export const sortTableFields = (a, b) => {
    if (!a.headerName || !b.headerName) return 0;
    let aHeader = a.headerName.toLowerCase();
    let bHeader = b.headerName.toLowerCase();
    if (aHeader < bHeader) return -1;
    if (aHeader > bHeader) return 1;
    return 0;
};

//Method that we can use to show grouped fields from EntityConfig on the field selector menu
export const groupFieldsForFieldSelector = (config, fields, isNewTable) => {
    const columnSelectorSections = config.columnSelectorSections;
    if (!columnSelectorSections) return fields;
    const mappedFields = fields.reduce((obj, field) => {
        if (isNewTable) obj[field.colId] = field;
        else obj[field.field] = field;
        return obj;
    }, {});

    let groupedFields = columnSelectorSections.map((current) => {
        let group = {
            label: isNewTable ? getLiteral(current.label) : current.label,
        };

        if (current.fields && current.fields.length > 0) {
            group.fields = current.fields.reduce((arr, field) => {
                if (mappedFields[field]) {
                    arr.push(mappedFields[field]);
                    mappedFields[field].included = true;
                }
                return arr;
            }, []);
        } else {
            const otherFields = Object.keys(mappedFields).reduce((arr, field) => {
                if (!mappedFields[field].included) {
                    arr.push(mappedFields[field]);
                }
                return arr;
            }, []);

            group.fields = otherFields.sort(sortTableFields);
        }
        return group;
    });

    return groupedFields;
};

export const checkIfEntityIsWeb4 = (entity) => {
    const {
        idImplementacion,
        newAccounts,
        newContacts,
        newOpportunities,
        newCalendarWeb,
        newActivitiesWeb,
    } = Context.config.userData;
    return (
        idImplementacion === '8004' ||
        (entity === COMPANIES && newAccounts) ||
        (entity === CONTACTS && newContacts) ||
        (entity === OPPORTUNITIES && newOpportunities) ||
        (entity === AGENDA && newCalendarWeb) ||
        (entity === TASKS && newCalendarWeb) ||
        (entity === ACTIVITIES && newActivitiesWeb)
    );
};

export const getCrudRoute = (entity, id, queryString) => {
    let route = '';
    switch (entity) {
        case AGENDA:
            route = getEnvironmentRoute(`${AGENDA.route}/event/${id}/edit`);
            break;
        case TASKS:
            route = getEnvironmentRoute(`${AGENDA.route}/task/${id}/edit`);
            break;
        case OPPORTUNITIES:
            route = getEnvironmentRoute(
                id ? `/opportunities/${id}/edit` : '/opportunities/new',
                queryString,
            );
            break;
        case SALESORDERS:
            route = getEnvironmentRoute(`${SALESORDERS.route}/${id}/edit`);
            break;
        case CONTACTS:
            route = getEnvironmentRoute(id ? `/contacts/${id}/edit` : '/contacts/new', queryString);
            break;
    }
    return route;
};

export const getDetailRoute = (props) => {
    const { entity, id, activityType, tab } = props;
    let route = '';
    switch (entity) {
        case AGENDA:
            route = getEnvironmentRoute(`${AGENDA.route}/event/${id}/edit`);
            break;
        case TASKS:
            route = getEnvironmentRoute(`${AGENDA.route}/task/${id}`);
            break;
        case COMPANIES:
            route = getEnvironmentRoute(`/companies/${id}`);
            break;
        case OPPORTUNITIES:
            route = getEnvironmentRoute(`/opportunities/${id}`);
            break;
        case CONTACTS:
            route = getEnvironmentRoute(`/contacts/${id}`);
            break;
        case ACTIVITIES:
            if (tab)
                switch (tab) {
                    case 'timeline':
                        route = getEnvironmentRoute(
                            `/conversations/activities/${id}/${activityType}/${tab}`,
                        );
                        break;
                    default:
                        route = getEnvironmentRoute(`/activities/${id}/${activityType}/${tab}`);
                        break;
                }
            else {
                route = getEnvironmentRoute(`/activities/${id}/${activityType}`);
            }
            break;
        case SALESORDERS:
            route = getEnvironmentRoute(`/sales-orders/${id}`);
            break;
    }
    return route;
};

export const getBackendBoolean = (fieldValue) => {
    if (typeof fieldValue === 'boolean') return fieldValue;
    if (fieldValue === 'True' || fieldValue === 'true') return true;
    if (fieldValue === '1' || fieldValue === 1) return true;
    return false;
};

export const parseBooleanForBackend = (fieldValue) => {
    if (fieldValue === '1') return fieldValue;
    if (typeof fieldValue === 'boolean' && fieldValue) return '1';
    if (!isNaN(fieldValue) && fieldValue === 1) return '1';
    if (fieldValue === 'True' || fieldValue === 'true') return '1';
    return '0';
};

export const isBackendFalsy = (value) => {
    return !value || value === -1 || value === '-1' || value === 'False' || value === '0';
};

export const getBackendQuantumValue = (obj, key) => {
    if (obj[key]) return obj[key];
    const quantumStates = [
        key.toLowerCase(),
        key.toUpperCase(),
        key[0].toUpperCase() + key.slice(1),
        key[0].toLowerCase() + key.slice(1),
    ];
    return obj[quantumStates.find((state) => obj[state])];
};

export const sha1FM = (toHash) => {
    let sha1Code = sha1(toHash);
    return sha1Code
        .match(/(..?)/g)
        .reduce((result, pair) => {
            pair[0] === '0' ? result.push(pair[1]) : result.push(pair);
            return result;
        }, [])
        .join('');
};

export const findFieldConfiguration = (field, entity) => {
    if (!field || !entity) return { configField: null, isExtra: false };
    field = field.toLowerCase(); // better matching... because backend
    const state = Context.store.getState();
    const standardFieldsConfiguration = state.config.standardFieldsSchema || null;
    const extraFieldsConfiguration = state.config.extraFields || null;

    const standardFields = standardFieldsConfiguration[entity.extraFieldName];

    let configField = standardFields.find((f) => f.id.toLowerCase() === field);
    if (configField) {
        return { configField: configField, isExtra: false };
    }

    let extraFields = extraFieldsConfiguration.find((e) => e.name === entity.extraFieldName);
    extraFields = extraFields?.field || [];
    configField = extraFields.find((ef) => ef.id.toLowerCase() === field);
    if (configField) {
        return { configField, isExtra: true };
    }

    return { configField: null, isExtra: false };
};

const multipleValuesFilter = (filter) => {
    if (Array.isArray(filter.value)) {
        const finalFilter = filter.completeValues.map((current) => {
            return {
                Id: current.value,
                Value: current.label,
                IndexValue: current.value,
            };
        });
        return finalFilter;
    } else return [];
};

const buildFilterForMailchimp = (filterKey, filter) => {
    switch (filterKey) {
        case 'companyType':
        case 'companyState':
        case 'environment':
        case 'users':
        case 'owner1':
        case 'owner2':
        case 'owner3':
        case 'owner4':
        case 'owner5':
            return multipleValuesFilter(filter);
        case 'activity':
            return [
                {
                    ValueFrom: filter.completeValues.valueFrom,
                    ValueTo: filter.completeValues.valueTo,
                },
            ];
        case 'idView':
            return filter.value.toString() || '';
        default:
            return filter.value;
    }
};

const buildExtraFilter = (filter) => {
    let extraFilter = {
        DataEntity: filter.valueListName,
        Description: filter.description,
        isAudit: filter.isAudit,
    };

    extraFilter = {
        ...extraFilter,
        ...formatExtra(filter.id, filter.value, filter.dataType, filter.isAudit),
    };

    return extraFilter;
};

// There are only 7 filters because are those that are used in mailchimp Web3
const backendFilterKeys = {
    environment: 'Enviroment',
    followingItem: 'FollowingItem',
    companyType: 'Type',
    activity: 'DataRange',
    companyState: 'CompanyState',
    idView: 'IdView',
    users: 'Users',
    owner1: 'Owner1',
    owner2: 'Owner2',
    owner3: 'Owner3',
    owner4: 'Owner4',
    owner5: 'Owner5',
    matchingName: 'MatchingName',
    phone: 'PhoneNumber',
    hasAccount: 'HasAccount',
    hasContact: 'HasContact',
    hasActivity: 'HasActivity',
    hasOpportunity: 'HasOpportunity',
    hasOrder: 'HasOrder',
};

// TODO when we will get rid of Web3 this method shouldn't be necessary, and this parsing
// >> should be done in the proxygo like it's done in the in the calls for the getEntity
// >> where we use the SearchInfo.go to parse the filters.
export const getFiltersForMailchimp = (filters) => {
    const extraFields = [];
    let finalFilters = Object.keys(filters).reduce((obj, current) => {
        const filter = filters[current];
        if (backendFilterKeys[current]) {
            obj[backendFilterKeys[current]] = buildFilterForMailchimp(current, filter);
        } else if (filter.isExtra || filter.asExtra) {
            extraFields.push(buildExtraFilter(filter));
        }
        return obj;
    }, {});

    finalFilters.ExtrafieldsFilter = [];

    if (filters.customView) {
        extraFields.push({
            SelectFilterId: filters.customView.SelectFilterId,
            SelectFilterParameters: JSON.stringify(filters.customView.SelectFilterParameters),
        });
    }

    finalFilters.ExtrafieldsFilter = extraFields;

    return finalFilters || {};
};

export const defineBaseExtraField = (extraField, extraParams) => {
    const state = Context.store.getState();
    const locale = state?.config?.userData?.locale || '';

    /*
        extraParams structure
        {
            decimalAndIntegerParams: { emptyIfNull: Boolean },
        }
     */

    let newExtraField = {
        colId: extraField.id,
        field: extraField.id.toLowerCase(),
        hide: true,
        headerName: extraField.description,
        sortField: extraField.id,
        cellRenderer: 'textCell',
        cellRendererParams: {},
        width: 200,
        suppressSizeToFit: true,
    };

    if (extraField.dataType === 'bool') newExtraField.cellRenderer = 'booleanCell';
    else if (extraField.dataType === 'decimal' || extraField.dataType === 'integer') {
        newExtraField.cellRenderer = 'numberCell';
        newExtraField.cellRendererParams.localeStringOptions = {
            useGrouping: true,
            minimumFractionDigits: extraField.dataType === 'decimal' ? 2 : 0,
        };
        if (extraParams?.decimalAndIntegerParams) {
            newExtraField.cellRendererParams.otherOptions = {
                ...extraParams.decimalAndIntegerParams,
            };
        }
    } else if (extraField.dataType === 'currency') {
        newExtraField.cellRenderer = 'currencyCell';
        if (extraParams?.currencyParams) {
            newExtraField.cellRendererParams.otherFields = {
                ...extraParams.currencyParams,
            };
        } else newExtraField.cellRendererParams.otherFields = {};
        newExtraField.cellRendererParams.otherFields.symbol = 'currencySymbol';
    } else if (extraField.dataType === 'date') {
        newExtraField.cellRenderer = 'dateCell';
        newExtraField.cellRendererParams = {
            ...newExtraField.cellRendererParams,
            locale,
            outputFormat: 'P',
        };
    } else if (extraField.dataType === 'multipleValueList') {
        newExtraField.cellRenderer = 'multiValueCell';
        newExtraField.cellRendererParams.splitter = ';';
        newExtraField.sortable = false;
    }

    if (
        extraField.dataType === 'decimal' ||
        extraField.dataType === 'integer' ||
        extraField.dataType === 'date' ||
        extraField.dataType === 'currency'
    ) {
        newExtraField.cellRendererParams = {
            ...newExtraField.cellRendererParams,
            align: 'right',
        };
    }

    if (extraField.dataType === 'percent') {
        newExtraField.headerComponent = 'headerTextCell';
        newExtraField.headerComponentParams = {
            isPercentage: true,
        };
    }

    return newExtraField;
};

export function updateTableOrderAndVisibility(newOrder, columnsDef) {
    const columnDefMap = columnsDef.reduce((obj, def) => {
        obj[def.colId] = def;
        return obj;
    }, {});

    let newColumnsDef = newOrder
        .filter((column) => columnDefMap[column])
        .map((column) => {
            return { ...columnDefMap[column], hide: false };
        });

    return [
        ...newColumnsDef,
        ...columnsDef.reduce((arr, def) => {
            if (!newOrder.includes(def.colId)) {
                arr.push({ ...def, hide: true });
            }
            return arr;
        }, []),
    ];
}

export function processExtraFieldsForList(fields) {
    return fields
        .filter((field) => {
            if (field.hasOwnProperty('includeInView') && !field.includeInView) return false;
            return true;
        })
        .map((extraField) => defineBaseExtraField(extraField));
}

function lowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function transformSearchUnit(searchUnit = {}) {
    return searchUnit.Id;
}

function transformSearchUnits(searchUnits = []) {
    return searchUnits.map(transformSearchUnit);
}

export function transformWeb3ActivitiesFilters(filters) {
    let newFilters = {};
    Object.keys(filters).forEach((key) => {
        switch (key) {
            case 'IdView':
                newFilters.idView = filters.IdView ? String(filters.IdView) : '';
                break;
            case 'MatchingName':
                newFilters.search = filters.MatchingName;
                break;
            case 'ActivityType':
            case 'FollowingItem':
            case 'ExtrafieldsFilter':
            case 'ListCheckInType':
            case 'WorkflowType':
            case 'VideoCallType':
            case 'VideoCallCheckInType':
                newFilters[lowerFirstLetter(key)] = filters[key];
                break;
            case 'Enviroment':
                newFilters.environment = filters[key] ? transformSearchUnits(filters[key]) : [];
                break;
            case 'CallType':
                if (filters[key]) newFilters.callType = parseInt(filters[key], 10);
                break;
            case 'Type':
                newFilters.activitySubType = filters[key] ? transformSearchUnits(filters[key]) : [];
                break;
            case 'Users':
            case 'Contacts':
            case 'Opportunities':
                newFilters[lowerFirstLetter(key)] = filters[key]
                    ? transformSearchUnits(filters[key])
                    : [];
                break;
            case 'Company':
                newFilters.IdCompany = parseInt(transformSearchUnit(filters[key]), 10);
                break;
            case 'StartDate':
                newFilters.dateMin = filters.StartDate;
                break;
            case 'EndDate':
                newFilters.dateMax = filters.EndDate;
                break;
            default:
                console.error(`${key} filter not transformed.`);
                break;
        }
    });

    return newFilters;
}

export function checkIsTrialExpired() {
    const state = Context.store.getState();
    const config = state.config;
    const subscriptionModeCode = config.subscriptionModeCode;
    const trialPeriodDaysRemaining = parseInt(config.userData.trialPeriodDaysRemaining, 10);
    return subscriptionModeCode === 'free_trial' && trialPeriodDaysRemaining <= 0;
}

export function isFree() {
    const state = Context.store.getState();
    const config = state.config;
    const subscriptionModeCode = config.subscriptionModeCode;
    return subscriptionModeCode.includes('free');
}

export function isFreeTrial() {
    const state = Context.store.getState();
    const config = state.config;
    const subscriptionModeCode = config.subscriptionModeCode;
    return subscriptionModeCode === 'free_trial';
}

export function isEssential() {
    const state = Context.store.getState();
    const config = state.config;
    const licenseEditionCode = config.licenseEditionCode;
    return licenseEditionCode === 'essential';
}

export function isProfessionalPlan() {
    const state = Context.store.getState();
    const config = state.config;
    const licenseEditionCode = config.licenseEditionCode;
    return licenseEditionCode.includes('professional');
}

export function isPerformancePlan() {
    const state = Context.store.getState();
    const config = state.config;
    const licenseEditionCode = config.licenseEditionCode;
    return licenseEditionCode.includes('performance');
}

export function parsePolicies(data) {
    let policy = [];

    let {
        requireLowerCase,
        requireLowerCaseLetters,
        lowercaseLettersMin,
        lowerCaseLettersMinNumber,
        regexLowerCase,
        requireUpperCase,
        requireUpperCaseLetters,
        uppercaseLettersMin,
        upperCaseLettersMinNumber,
        regexUpperCase,
        requireDigits,
        digitsMin,
        digitsMinNumber,
        regexDigits,
        minLength,
        maxLength,
        requireSpecialLetters,
        specialLettersMin,
        specialLettersMinNumber,
        regexSpecial,
    } = data;

    requireLowerCase = requireLowerCase || requireLowerCaseLetters;
    lowercaseLettersMin = lowercaseLettersMin || lowerCaseLettersMinNumber;
    requireUpperCase = requireUpperCase || requireUpperCaseLetters;
    uppercaseLettersMin = uppercaseLettersMin || upperCaseLettersMinNumber;
    digitsMin = digitsMin || digitsMinNumber;
    specialLettersMin = specialLettersMin || specialLettersMinNumber;

    if (requireLowerCase && lowercaseLettersMin) {
        let literal =
            lowercaseLettersMin > 1
                ? 'label_minimum_lowercase_characters_password_policy'
                : 'label_minimum_lowercase_characters_password_policy_one';

        policy.push({
            literal,
            number: lowercaseLettersMin,
            condition(password) {
                let reg = new RegExp(regexLowerCase);
                return password && reg.test(password);
            },
        });
    }

    if (requireUpperCase && uppercaseLettersMin) {
        let literal =
            uppercaseLettersMin > 1
                ? 'label_minimum_uppercase_characters_password_policy'
                : 'label_minimum_uppercase_characters_password_policy_one';

        policy.push({
            literal,
            number: uppercaseLettersMin,
            condition(password) {
                let reg = new RegExp(regexUpperCase);
                return password && reg.test(password);
            },
        });
    }

    if (requireDigits && digitsMin) {
        let literal =
            digitsMin > 1
                ? 'label_minimum_numbers_password_policy'
                : 'label_minimum_numbers_password_policy_one';

        policy.push({
            literal,
            number: digitsMin,
            condition(password) {
                let reg = new RegExp(regexDigits);
                return password && reg.test(password);
            },
        });
    }

    if (minLength > 1) {
        let literal = 'label_minimum_characters_password_policy';
        policy.push({
            literal,
            number: minLength,
            condition(password) {
                return password && password.length >= minLength;
            },
        });
    }

    if (maxLength > 1) {
        let literal = 'label_maximum_characters_password_policy';
        policy.push({
            literal,
            number: maxLength,
            condition(password) {
                return password && password.length <= maxLength;
            },
        });
    }

    if (requireSpecialLetters && specialLettersMin) {
        let literal =
            specialLettersMin > 1
                ? 'label_minimum_special_characters_password_policy'
                : 'label_minimum_special_characters_password_policy_one';

        let regex = regexSpecial;
        policy.push({
            literal,
            number: specialLettersMin,
            condition(password) {
                let reg = new RegExp(regex);
                return password && reg.test(password);
            },
        });
    }

    return policy;
}

export function parseServerDecimal(text) {
    return parseFloat(text.replace(',', '.'));
}

export function checkDeviceAccess() {
    return window.innerWidth > 700;
}

export function getWithLanguage(data, baseProp) {
    let currentLang = Context.config?.userData?.langISOInterface;
    currentLang = currentLang?.split('-')?.[1] || currentLang;
    if (currentLang === 'es') return data[baseProp];
    return data[`${baseProp}_${currentLang.toUpperCase()}`] || data[baseProp];
}

export function canImport() {
    const state = Context.store.getState();
    const isAdmin = state.config?.permission?.canConfigImplementation;
    const subscriptionModeCode = state.config?.subscriptionModeCode;
    const licenseEditionCode = state.config?.licenseEditionCode;
    const isSMB = state.config?.productModeCode === 'smb';
    return (
        isAdmin &&
        (subscriptionModeCode === 'free_trial' ||
            isSMB ||
            [
                'starter',
                'essential',
                'kitdigital_starter',
                'sage_starter',
                'sage50_starter',
            ].includes(licenseEditionCode))
    );
}
